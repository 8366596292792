import { IdType, Row } from 'react-table'

import { AssetData } from '../../../../../Blockchain/BCInterfaces'
import { TimestampRange } from '../../../../../Components/TxInputs/TimestampRangeSelect'

export type FilterFunction<T extends object, Y> = (rows: Row<T>[], columnIds: IdType<T>[], filterValue: Y) => Row<T>[]

export const dateFilter: FilterFunction<AssetData, TimestampRange> = (rows, _columnIds, filterValue) => {
  const { from, to } = filterValue

  return rows.filter((row) => row.original.issueTimestamp >= from && row.original.issueTimestamp <= to)
}
