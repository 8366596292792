import React from 'react'
import { AppBar, Toolbar } from '@mui/material'
import Typography from '@mui/material/Typography'
import AccountInfo from '../Components/AccountInfo'

const TopBar: React.FC = () => {
  return (
    <AppBar position="fixed">
      <Toolbar>
        <img
          src="/images/skey-logo.svg"
          alt="skey-logo"
          style={{ width: 40, marginRight: 15 }}
        />
        <Typography variant="h6" fontWeight="bold" component="div" sx={{ flexGrow: 1 }}>
          SKey Utils
        </Typography>
        <AccountInfo />
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
