import { Alert } from '@mui/material'
import React from 'react'
import parse from 'csv-parse'
import { verifyAddress } from '@waves/ts-lib-crypto'
import Space from '../../Components/Space'
import DelimiterSelect from './DelimiterSelect'
import { useTransaction } from '../../Providers/WvsProvider/useTransaction'
import Card from '../../Components/Card'
import TxButton from '../../Components/TxButton'
import { useTransactionNotification } from '../../Providers/NotificationProvider/useTransactionNotification'
import { useWvs } from '../../Providers/WvsProvider/KeeperProvider'
import TxErrorDialog from '../../Components/TxErrorDialog'
import CustomTextarea from '../../Components/Inputs/CustomTextarea'
import { useCustomInput } from '../../Components/Inputs/CustomInput'

// EXAMPLE
/*
3EHdestphFFxmfaTLDpd7PaeucpUf81Nd1n,5
3ESqiT4DpbMrX2h7AoSsAqEdc6n6JqdGnq5,4
3EQbn4ddoYws6Tsf9j71sHwzHYopUCgFR8n,60
*/

export interface ParserEntry {
  address: string
  value: string
}

const parseInput = (text: string, delimiter: string) => {
  return new Promise<[ParserEntry[], string | null]>((resolve) => {
    parse(text, { comment: '#', delimiter }, (err, output) => {
      if (err) return resolve([[], `Parser error => ${err.message}`])

      const entries = output.map(([address, value]: any) => ({ address, value }))

      const validationError = validateEntries(entries)
      if (validationError) return resolve([[], validationError])

      resolve([entries, null])
    })
  })
}

const getPlaceholder = (delimiter: string) => {
  const d = delimiter === '\\t' ? '  ' : delimiter
  return `3EHdestphFFxmfaTLDpd7PaeucpUf81Nd1n${d}500\n3ESqiT4DpbMrX2h7AoSsAqEdc6n6JqdGnq5${d}400\n3EQbn4ddoYws6Tsf9j71sHwzHYopUCgFR8n${d}600000`
}

const validateEntries = (entries: ParserEntry[]) => {
  for (const entry of entries) {
    if (!verifyAddress(entry.address)) return `Address ${entry.address} is invalid`

    const num = Number(entry.value)

    if (!Number.isSafeInteger(num) || num <= 0) return `Value ${entry.value} is invalid`
  }

  if (entries.length >= 100) return 'Maximal amount of addresses is 100'

  return null
}

const MassTransferView: React.FC = () => {
  const { services } = useWvs()
  const text = useCustomInput({ required: true })
  const [entries, setEntries] = React.useState<ParserEntry[]>([])
  const [error, setError] = React.useState<string | null>(null)
  const [delimiter, setDelimiter] = React.useState(',')
  const tx = useTransaction()
  useTransactionNotification(tx.state.status)

  React.useEffect(() => {
    parseInput(text.state.value, delimiter).then(([entries, error]) => {
      setEntries(entries)
      setError(error)
    })
  }, [text.state.value, delimiter])

  const total = () => entries.reduce((acc, val) => acc + BigInt(val.value), 0n)

  const transfer = async () => {
    const params = services.txCreator.createMassTransferTxData(
      total().toString(),
      entries.map((entry) => ({
        recipient: entry.address,
        amount: entry.value
      }))
    )

    await tx.send(params)
  }

  return (
    <Card title="Mass transfer" maxWidth={600}>
      <CustomTextarea
        {...text}
        multiline
        rows={6}
        size="small"
        label="CSV"
        placeholder={getPlaceholder(delimiter)}
        spaceBottom
      />
      <DelimiterSelect id="mass-tx-delimiter" value={delimiter} onChange={setDelimiter} />
      <Space />
      {!error && !!entries.length && tx.state.status === 'ready' && (
        <React.Fragment>
          <Alert severity="success" variant="filled" sx={{ width: 'calc(100% - 40px)' }}>
            {entries.length} addresses and values imported
          </Alert>
          <Space />
        </React.Fragment>
      )}
      {error && (
        <React.Fragment>
          <Alert severity="error" variant="filled" sx={{ width: 'calc(100% - 40px)' }}>
            {error}
          </Alert>
          <Space />
        </React.Fragment>
      )}
      <TxButton
        tx={tx.state}
        onClick={transfer}
        text="Mass Transfer"
        disabled={!!error || !entries.length || tx.state.status !== 'ready'}
        reset={tx.reset}
        wrapper
      />
      <TxErrorDialog tx={tx.state} reset={tx.reset} />
    </Card>
  )
}

export default MassTransferView
