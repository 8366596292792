import moment from 'moment'
import { CellProps, Column } from 'react-table'
import { Typography } from '@mui/material'

import { AssetData } from '../../../../Blockchain/BCInterfaces'
import HashText from '../../../../Components/HashText'

import EllipsisText from './components/EllipsisText'
import NftDataPreview from '../NftFilterController/NftDataPreview'
import NftActions from './components/NftActions'
import { dateFilter } from './utils/nftTableFilters'

export const columnsSchema = (browsedAddress: string): Column<AssetData>[] => [
  {
    Header: 'Id',
    accessor: 'assetId',
    Cell: ({ value }) => <HashText text={value} explorerPath="/assets" substringLength={4} tooltip />
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ value }) => <EllipsisText text={value} maxWidth={150} float="right" />
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: ({ value }) => <EllipsisText text={value} maxWidth={250} float="right" />
  },
  {
    Header: 'Data',
    id: 'nftAssetData',
    Cell: (props: CellProps<AssetData, any>) => <NftDataPreview asset={props.row.original} />
  },
  {
    Header: 'Date',
    accessor: 'issueTimestamp',
    filter: dateFilter,
    Cell: ({ value }) => <Typography>{moment(value).format('DD-MM-YYYY HH:mm')}</Typography>
  },
  {
    Header: 'Actions',
    id: 'actions',
    Cell: (props: CellProps<AssetData, any>) => (
      <NftActions asset={props.row.original} browsedAddress={browsedAddress} />
    )
  }
]
