import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

import TxAddressInput, { initialTxAddressInputState } from '../../../../Components/TxInputs/TxAddressInput'
import TimestampRangeSelect, {
  TimestampRange,
  initialTimestampRangeSelectState
} from '../../../../Components/TxInputs/TimestampRangeSelect'
import Space from '../../../../Components/Space'

export interface NftFilterOptions {
  address: string | null
  timestampRange: TimestampRange
}

export interface NftFilterControllerProps {
  options: NftFilterOptions
  setOptions: React.Dispatch<React.SetStateAction<NftFilterOptions>>
}

export const initialNftFilterOptions = (): NftFilterOptions => ({
  address: initialTxAddressInputState(),
  timestampRange: initialTimestampRangeSelectState()
})

const NftFilterController = ({ options, setOptions }: NftFilterControllerProps) => {
  return (
    <Accordion sx={{ width: '100%', marginBottom: '0 !important' }}>
      <AccordionSummary expandIcon={<ExpandMore />}>Filter options</AccordionSummary>

      <AccordionDetails>
        <TxAddressInput state={options.address} setState={(address) => setOptions({ ...options, address })} />
        <Space height={25} />

        <TimestampRangeSelect
          state={options.timestampRange}
          setState={(timestampRange) => setOptions({ ...options, timestampRange })}
        />
        <Space height={10} />
      </AccordionDetails>
    </Accordion>
  )
}

export default NftFilterController
