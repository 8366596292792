import React from 'react'

export interface CustomInputState {
  value: string
  error: string | null
  touched: boolean
  required: boolean
}

export const initialCustomInputState = Object.freeze({
  value: '',
  error: null,
  touched: false,
  required: false
})

export const useCustomInput = (input: string | Partial<CustomInputState> = {}) => {
  const init =
    typeof input === 'string'
      ? { ...initialCustomInputState, value: input }
      : { ...initialCustomInputState, ...input }

  const [state, setState] = React.useState<CustomInputState>(init)
  return { state, setState }
}
