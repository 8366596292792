import { CircularProgress, Typography } from '@mui/material'
import React from 'react'
import Card from '../../Components/Card'
import Space from '../../Components/Space'
import { useNodeData } from '../../Providers/WvsProvider/useNodeData'
import DataEntriesTable from './DataEntriesTable'
import FilterController, { initialFilterOptions } from './FilterController'

const DataStorageView: React.FC = () => {
  const [filterOptions, setFilterOptions] = React.useState(initialFilterOptions())

  const dataRequest = useNodeData({
    initialValue: [],
    exec: async (service) => {
      if (!filterOptions.address) return []

      return await service.getData(filterOptions.address)
    }
  })

  React.useEffect(() => {
    dataRequest.forceFetch()

    // eslint-disable-next-line
  }, [filterOptions.address])

  return (
    <Card title="Data storage" maxWidth={1200} sx={{ marginBottom: '20px' }}>
      <FilterController options={filterOptions} setOptions={setFilterOptions} />
      <Space height={15} />
      {dataRequest.loading && <CircularProgress size={40} sx={{ margin: '20px 0' }} />}
      {dataRequest.error && <Typography>{dataRequest.error}</Typography>}
      {!dataRequest.error && !dataRequest.loading && (
        <DataEntriesTable entries={dataRequest.data} filterOptions={filterOptions} />
      )}
    </Card>
  )
}

export default DataStorageView
