import { Contact } from './Contact'

export class StorageService {
  private readonly key = 'contacts'

  getAll(): Contact[] {
    const storage = window.localStorage.getItem(this.key)
    if (!storage) return []

    return JSON.parse(storage)
  }

  insertOne(contact: Contact) {
    const contacts = this.getAll()

    const exists = contacts.find((item) => item.name === contact.name)
    if (exists) throw new Error('Contact already exists')

    const updated = JSON.stringify([...contacts, contact])

    window.localStorage.setItem(this.key, updated)
  }

  insertMany(contacts: Contact[]) {
    const previous = this.getAll()

    const newContacts: Contact[] = []

    for (const contact of contacts) {
      const exists = previous.find((item) => item.name === contact.name)
      if (exists) throw new Error('Contact already exists')

      newContacts.push(contact)
    }

    const updated = JSON.stringify([...previous, ...newContacts])

    window.localStorage.setItem(this.key, updated)
  }

  deleteOne(contact: Contact) {
    const updated = JSON.stringify(
      this.getAll().filter((item) => item.name !== contact.name)
    )

    window.localStorage.setItem(this.key, updated)
  }
}
