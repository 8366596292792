import React from 'react'
import { IconButton, IconButtonProps, Tooltip } from '@mui/material'

export interface ActionIconProps {
  icon: React.FC<any>
  tooltip?: string
  disabled?: boolean
  onClick?: () => void
  color?: IconButtonProps['color']
}

const ActionIcon: React.FC<ActionIconProps> = (props) => {
  const Icon = props.icon

  const icon = (
    <IconButton onClick={props.onClick} size="small" disabled={props.disabled}>
      <Icon fontSize="small" color={props.disabled ? 'disabled' : props.color} />
    </IconButton>
  )

  return props.disabled ? (
    icon
  ) : (
    <Tooltip title={props.tooltip ?? 'Action'}>{icon}</Tooltip>
  )
}

export default ActionIcon
