import React from 'react'
import { CircularProgress, Typography } from '@mui/material'
import AddressInput from '../../Components/Inputs/AddressInput'
import Space from '../../Components/Space'
import FunctionBox, { FunctionBoxProps } from './FunctionBox'
import Card from '../../Components/Card'
import { useCustomInput } from '../../Components/Inputs/CustomInput'
import { useContacts } from '../../Providers/ContactsProvider/ContactsProvider'
import { ScriptInfoMeta } from '../../Blockchain/BCInterfaces'
import { useNodeData } from '../../Providers/WvsProvider/useNodeData'

export const parseFunctionsMeta = (meta: ScriptInfoMeta): FunctionBoxProps[] => {
  return Object.entries(meta?.meta?.callableFuncTypes ?? {}).map(([key, value]) => ({
    name: key,
    fields: value,
    dapp: meta.address
  }))
}

const FunctionsView: React.FC = () => {
  const { availableContacts } = useContacts()
  const address = useCustomInput('')

  const ready = !(address.state.error || !address.state.touched)

  const metaRequest = useNodeData({
    initialValue: [],
    exec: async (service) => {
      if (!ready) return []

      const meta = await service.scriptInfoMeta(address.state.value)
      return parseFunctionsMeta(meta)
    }
  })

  React.useEffect(() => {
    metaRequest.forceFetch()

    // eslint-disable-next-line
  }, [address.state])

  return (
    <Card title="Functions" maxWidth={700}>
      <AddressInput {...address} label="dApp address" contacts={availableContacts} />
      <Space />
      {metaRequest.loading && <CircularProgress size={40} sx={{ margin: '25px 0' }} />}
      {ready && !metaRequest.data.length && !metaRequest.loading && (
        <Typography variant="body2" sx={{ margin: '25px 0' }}>
          No callable functions found.
        </Typography>
      )}
      {metaRequest.data.map((props) => (
        <FunctionBox {...props} key={props.name} />
      ))}
    </Card>
  )
}

export default FunctionsView
