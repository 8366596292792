import { Box, useTheme } from '@mui/material'
import React from 'react'
import Menu from './Menu'
import { routes } from './Routes'
import TopBar from './TopBar'

const Layout: React.FC = (props) => {
  const { palette } = useTheme()

  return (
    <React.Fragment>
      <TopBar />
      <Box
        sx={{
          display: 'flex',
          backgroundColor: palette.background.default,
          minHeight: '100vh'
        }}
      >
        <Menu routes={routes} />
        <Box
          sx={{
            marginTop: '86px',
            width: '100%',
            display: 'flex',
            marginLeft: '270px',
            marginRight: '20px'
          }}
        >
          {props.children}
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default Layout
