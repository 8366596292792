import React from 'react'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterMoment from '@mui/lab/AdapterMoment'

import { routes } from './Navigation/Routes'
import ThemeProvider from './Providers/ThemeProvider/ThemeProvider'
import { WvsProvider } from './Providers/WvsProvider/KeeperProvider'
import { NotificationProvider } from './Providers/NotificationProvider/NotificationProvider'
import { ContactsProvider } from './Providers/ContactsProvider/ContactsProvider'
import { SettingsProvider } from './Providers/SettingsProvider/SettingsProvider'
import { ExploreNftsProvider } from './Views/ExploreNfts/contexts/ExploreNfts.context'

import Layout from './Navigation/Layout'
import Navigation from './Navigation/Navigation'
import LoadingOverlay from './Components/LoadingOverlay'

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <SettingsProvider>
          <NotificationProvider>
            <WvsProvider connectOnLoad>
              <ThemeProvider>
                <ContactsProvider>
                  <ExploreNftsProvider>
                    <LoadingOverlay />
                    <Navigation routes={routes} layout={Layout} defaultPath="/info" />
                  </ExploreNftsProvider>
                </ContactsProvider>
              </ThemeProvider>
            </WvsProvider>
          </NotificationProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </React.StrictMode>
  )
}

export default App
