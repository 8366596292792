import React from 'react'
import AddressField from '../../Components/Inputs/AddressInput'
import AmountField from '../../Components/Inputs/AmountInput'
import Card from '../../Components/Card'
import { useTransaction } from '../../Providers/WvsProvider/useTransaction'
import CustomSelect from '../../Components/Inputs/CustomSelect'
import { useCustomInput } from '../../Components/Inputs/CustomInput'
import TxButton from '../../Components/TxButton'
import { useContacts } from '../../Providers/ContactsProvider/ContactsProvider'
import { useTransactionNotification } from '../../Providers/NotificationProvider/useTransactionNotification'
import { useWvs } from '../../Providers/WvsProvider/KeeperProvider'
import TxErrorDialog from '../../Components/TxErrorDialog'
import { useForm } from '../../Common/useForm'
import CustomTextarea from '../../Components/Inputs/CustomTextarea'
import { FormControlLabel, Switch } from '@mui/material'
import AssetIdInput from '../../Components/Inputs/AssetIdInput'

const TransferView: React.FC = () => {
  const { services } = useWvs()
  const address = useCustomInput({ required: true })
  const { availableContacts } = useContacts()
  const amount = useCustomInput({ value: '1', touched: true })
  const denomination = useCustomInput('unit')
  const attachment = useCustomInput()
  const assetId = useCustomInput()
  const [customAsset, setCustomAsset] = React.useState(false)
  const tx = useTransaction()

  const { ready: mainFormReady } = useForm([address.state, amount.state, denomination.state, attachment.state])

  const customAssetReady = assetId.state.touched && !assetId.state.error
  const ready = customAsset ? mainFormReady && customAssetReady : mainFormReady

  useTransactionNotification(tx.state.status)

  const normalizeAmount = (amount: string, denomination: string) => {
    if (denomination === 'unit') return amount

    return Math.floor(Number(amount) * 10 ** 8).toString()
  }

  const transfer = async () => {
    const params = await services.txCreator.createTransferTxData(
      address.state.value,
      normalizeAmount(amount.state.value, denomination.state.value),
      customAsset ? assetId.state.value : '',
      attachment.state.value
    )

    await tx.send(params)
  }

  return (
    <Card title="Transfer" sx={{ alignItems: 'flex-start' }} maxWidth={450}>
      <AddressField
        {...address}
        disabled={tx.state.status !== 'ready'}
        required
        label="Receiver address"
        contacts={availableContacts}
        spaceBottom
      />
      <AmountField
        {...amount}
        denomination={denomination.state.value}
        disabled={tx.state.status !== 'ready'}
        required
        spaceBottom
      />
      <CustomSelect
        {...denomination}
        disabled={tx.state.status !== 'ready'}
        label="Denomination"
        options={[
          { value: 'unit', label: 'Unit' },
          { value: 'coin', label: <span>Coin (10&#8312;)</span> }
        ]}
        spaceBottom
      />
      <CustomTextarea
        {...attachment}
        label="Attachment"
        disabled={tx.state.status !== 'ready'}
        multiline
        maxRows={4}
        inputProps={{ maxLength: 140 }}
        spaceBottom
      />
      <FormControlLabel
        control={<Switch onChange={(e) => setCustomAsset(e.target.checked)} checked={customAsset} />}
        label="Custom asset"
        sx={{ marginLeft: '-2px', marginBottom: '20px' }}
      />
      {customAsset && <AssetIdInput {...assetId} spaceBottom />}
      <TxButton tx={tx.state} disabled={!ready} text="Transfer" onClick={transfer} reset={tx.reset} wrapper />
      <TxErrorDialog tx={tx.state} reset={tx.reset} />
    </Card>
  )
}

export default TransferView
