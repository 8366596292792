import React from 'react'

import { useCustomInput } from '../Inputs/CustomInput'
import { useContacts } from '../../Providers/ContactsProvider/ContactsProvider'
import { useWvs } from '../../Providers/WvsProvider/KeeperProvider'

import AddressInput from '../Inputs/AddressInput'

export interface TxAddressInputProps {
  state: string | null
  setState: (state: string | null) => void
}

export const initialTxAddressInputState = (): string | null => null

const TxAddressInput: React.FC<TxAddressInputProps> = ({ state, setState }) => {
  const address = useCustomInput()
  const { publicState } = useWvs()
  const { availableContacts } = useContacts()

  React.useEffect(() => {
    setState(publicState?.account?.address ?? null)

    // eslint-disable-next-line
  }, [publicState?.account])

  React.useEffect(() => {
    if (address.state.error || !address.state.touched || address.state.value === '')
      return

    setState(address.state.value)

    // eslint-disable-next-line
  }, [address.state])

  React.useEffect(() => {
    if (state === null) return

    address.setState((s) => ({ ...s, value: state }))

    // eslint-disable-next-line
  }, [state])

  return (
    <AddressInput
      {...address}
      label="Address"
      contacts={availableContacts}
      size="small"
    />
  )
}

export default TxAddressInput
