import React from 'react'
import { Chip, Avatar, ChipProps } from '@mui/material'

export interface InfoChipProps {
  label: string
  value?: string
  color?: ChipProps['color']
  onClick?(): void
}

const InfoChip: React.FC<InfoChipProps> = (props) => {
  return (
    <Chip
      label={props.value ?? 'N/A'}
      variant="outlined"
      color={props.color}
      avatar={
        <Avatar
          sx={{
            width: 'min-content !important',
            borderRadius: '15px',
            padding: '0 10px'
          }}
        >
          {props.label}
        </Avatar>
      }
      sx={{ transform: 'scale(1.1)', marginRight: '30px' }}
      onClick={props.onClick}
    />
  )
}

export default InfoChip
