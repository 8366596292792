import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export interface RedirectProps {
  from: string
  to: string
}

const Redirect: React.FC<RedirectProps> = (props) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (pathname === props.from) {
      navigate(props.to)
    }
  }, [pathname, navigate, props])

  return null
}

export default Redirect
