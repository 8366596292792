import React from 'react'
import Card from '../../Components/Card'
import { useCustomInput } from '../../Components/Inputs/CustomInput'
import { useTransaction } from '../../Providers/WvsProvider/useTransaction'
import TxButton from '../../Components/TxButton'
import { useForm } from '../../Common/useForm'
import { useWvs } from '../../Providers/WvsProvider/KeeperProvider'
import { useTransactionNotification } from '../../Providers/NotificationProvider/useTransactionNotification'
import TxErrorDialog from '../../Components/TxErrorDialog'
import CustomTextarea from '../../Components/Inputs/CustomTextarea'

export interface LeaseCancelFormProps {
  forceUpdate: () => void
}

const LeaseCancelForm: React.FC<LeaseCancelFormProps> = (props) => {
  const id = useCustomInput({ required: true })
  const tx = useTransaction()
  const { ready } = useForm([id.state])
  const { services } = useWvs()
  useTransactionNotification(tx.state.status)

  const send = async () => {
    const params = services.txCreator.createLeaseCancelTxData(id.state.value)
    await tx.send(params)
    props.forceUpdate()
  }

  return (
    <Card title="Cancel lease" sx={{ alignItems: 'flex-start' }} maxWidth={500}>
      <CustomTextarea {...id} label="Id" required spaceBottom />
      <TxButton
        tx={tx.state}
        text="Cancel lease"
        onClick={send}
        reset={tx.reset}
        wrapper
        disabled={!ready}
      />
      <TxErrorDialog tx={tx.state} reset={tx.reset} />
    </Card>
  )
}

export default LeaseCancelForm
