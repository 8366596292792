// Icons
import DashboardIcon from '@mui/icons-material/Dashboard'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import Filter9PlusIcon from '@mui/icons-material/Filter9Plus'
import StorageIcon from '@mui/icons-material/Storage'
import CodeIcon from '@mui/icons-material/Code'
import FunctionsIcon from '@mui/icons-material/Functions'
import BadgeIcon from '@mui/icons-material/Badge'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import RecentActorsIcon from '@mui/icons-material/RecentActors'
import ReceiptIcon from '@mui/icons-material/Receipt'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import SettingsIcon from '@mui/icons-material/Settings'
import AddBoxIcon from '@mui/icons-material/AddBox'

// Views
import InfoView from '../Views/Info/InfoView'
import TransferView from '../Views/Transfer/TransferView'
import MassTransferView from '../Views/MassTransfer/MassTransferView'
import DataView from '../Views/Data/DataView'
import ScriptView from '../Views/Script/ScriptView'
import FunctionsView from '../Views/Functions/FunctionsView'
import AliasView from '../Views/Alias/AliasView'
import IssueView from '../Views/Issue/IssueView'
import BurnView from '../Views/Burn/BurnView'
import LeaseView from '../Views/Lease/LeaseView'
import ContactsView from '../Views/Contacts/ContactsView'
import TransactionsView from '../Views/Transactions/TransactionsView'
import ExploreNfts from '../Views/ExploreNfts/ExploreNfts'
import DataStorageView from '../Views/DataStorage/DataStorageView'
import AddressGenerator from '../Views/AddressGenerator/AddressGeneratorView'
import SettingsView from '../Views/Settings/SettingsView'

export interface Route {
  path: string
  label: string
  icon: React.FC
  component: React.FC<{}>
  divider?: boolean
}

export const routes: readonly Route[] = Object.freeze([
  {
    path: '/info',
    label: 'Info',
    icon: DashboardIcon,
    component: InfoView,
    divider: true
  },
  {
    path: '/transfer',
    label: 'Transfer',
    icon: AttachMoneyIcon,
    component: TransferView
  },
  {
    path: '/mass_transfer',
    label: 'Mass transfer',
    icon: Filter9PlusIcon,
    component: MassTransferView
  },
  {
    path: '/data',
    label: 'Data',
    icon: StorageIcon,
    component: DataView
  },
  {
    path: '/script',
    label: 'Script',
    icon: CodeIcon,
    component: ScriptView
  },
  {
    path: '/functions',
    label: 'Functions',
    icon: FunctionsIcon,
    component: FunctionsView
  },
  {
    path: '/alias',
    label: 'Alias',
    icon: BadgeIcon,
    component: AliasView
  },
  {
    path: '/issue',
    label: 'Issue',
    icon: AccountBalanceIcon,
    component: IssueView
  },
  {
    path: '/burn',
    label: 'Burn',
    icon: LocalFireDepartmentIcon,
    component: BurnView
  },
  {
    path: '/lease',
    label: 'Lease',
    icon: MonetizationOnIcon,
    component: LeaseView,
    divider: true
  },
  {
    path: '/contacts',
    label: 'Contacts',
    icon: RecentActorsIcon,
    component: ContactsView
  },
  {
    path: '/transactions',
    label: 'Transactions',
    icon: ReceiptIcon,
    component: TransactionsView
  },
  {
    path: '/explore-nfts',
    label: 'NFT',
    icon: InsertPhotoIcon,
    component: ExploreNfts
  },
  {
    path: '/data_storage',
    label: 'Data storage',
    icon: StorageIcon,
    component: DataStorageView,
    divider: true
  },
  {
    path: '/address_generator',
    label: 'Address generator',
    icon: AddBoxIcon,
    component: AddressGenerator
  },
  {
    path: '/settings',
    label: 'Settings',
    icon: SettingsIcon,
    component: SettingsView
  }
])
