import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'

export interface ConfirmationDialogProps {
  open: boolean
  setOpen: (value: boolean) => void
  callback: () => void
  title?: string
  text?: string
  cancelButtonText?: string
  continueButtonText?: string
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {
  const onClick = () => {
    props.setOpen(false)
    props.callback()
  }

  return (
    <Dialog open={props.open} onClose={() => props.setOpen(false)}>
      <DialogTitle>{props.title ?? 'Warning'}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => props.setOpen(false)}>
          {props.cancelButtonText ?? 'Cancel'}
        </Button>
        <Button onClick={onClick}>{props.continueButtonText ?? 'Continue'}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
