import { Alert, Button } from '@mui/material'
import React from 'react'
import Card from '../../Components/Card'
import Space from '../../Components/Space'
import Dropzone from '../../Components/Inputs/Dropzone'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { useContacts } from '../../Providers/ContactsProvider/ContactsProvider'
import { Contact } from '../../Providers/ContactsProvider/Contact'
import parse from 'csv-parse'
import { verifyAddress } from '@waves/ts-lib-crypto'
import { inspect } from 'util'

export const saveFile = (filename: string, content: string, type: string) => {
  const blob = new Blob([content], { type })
  const elem = window.document.createElement('a')
  elem.href = window.URL.createObjectURL(blob)
  elem.download = filename
  window.document.body.appendChild(elem)
  elem.click()
  document.body.removeChild(elem)
}

export const validateContacts = (
  contacts: Contact[],
  validateFunc: (contact: Contact) => string | null
): string | null => {
  for (const index in contacts) {
    const err = validateFunc(contacts[Number(index)])
    if (err) return `Contact #${index} error. ${err}.`
  }

  return null
}

export const parseCsv = (data: string): Promise<[Contact[], string | null]> => {
  return new Promise((resolve) => {
    parse(data, { comment: '#', delimiter: ',' }, (err, output) => {
      if (err) return resolve([[], `Parser error => ${err.message}`])

      const contacts: Contact[] = output.map((line: string) => ({
        address: line[0],
        chainId: line[1],
        name: line[2]
      }))

      resolve([contacts, null])
    })
  })
}

export const validateContact = (contact: Contact): string | null => {
  if (!contact.address) return 'address missing'
  if (!contact.name) return 'name missing'
  if (!contact.chainId) return 'chainId missing'

  if (contact.name.includes(',')) return 'name contains comma'
  if (contact.chainId.length !== 1) return 'chainId is invalid'
  if (!verifyAddress(contact.address)) return 'address is invalid'

  return null
}

export const uniqueContacts = (newContacts: Contact[], contacts: Contact[]) => {
  return newContacts.filter(
    (newContact) => !contacts.find((c) => c.name === newContact.name)
  )
}

const ImportContacts: React.FC = () => {
  const { contacts, addContacts } = useContacts()
  const [imported, setImported] = React.useState<Contact[] | null>(null)
  const [error, setError] = React.useState<string | null>(null)
  const [text, setText] = React.useState('')

  const onExport = () => {
    const content = contacts.map((c) => `${c.address},${c.chainId},${c.name}`).join('\n')
    saveFile('export.csv', content, 'text/csv')
  }

  const onDrop = async () => {
    if (!text) return console.error('no content')

    setImported(null)
    setError(null)

    const [imported, parseError] = await parseCsv(text)
    if (parseError) return setError(parseError)

    const validationError = validateContacts(imported, validateContact)
    if (validationError) return setError(validationError)

    const unique = uniqueContacts(imported, contacts)

    setImported(unique)
  }

  const onImport = () => {
    if (!imported) return

    addContacts(imported)
    setImported(null)
  }

  React.useEffect(() => {
    if (!text) return

    onDrop()

    // eslint-disable-next-line
  }, [text])

  return (
    <Card title="Import / Export" maxWidth={450}>
      <Dropzone state={text} setState={setText} fullWidth />
      {imported && (
        <Alert
          severity="info"
          variant="filled"
          sx={{ width: 'calc(100% - 35px)', marginTop: '20px' }}
        >
          {imported.length} contacts can be imported
        </Alert>
      )}
      {!!error && (
        <Alert
          severity="error"
          variant="filled"
          sx={{ width: 'calc(100% - 35px)', marginTop: '20px' }}
        >
          {inspect(error)}
        </Alert>
      )}
      <Space />
      <Button
        variant="contained"
        fullWidth
        endIcon={<FileUploadIcon />}
        disabled={!imported?.length}
        onClick={onImport}
      >
        Import contacts
      </Button>
      <Space height={10} />
      <Button
        variant="contained"
        fullWidth
        endIcon={<FileDownloadIcon />}
        onClick={onExport}
        disabled={contacts.length === 0}
      >
        Export contacts
      </Button>
    </Card>
  )
}

export default ImportContacts
