import { Button } from '@mui/material'
import React from 'react'
import Card from '../../Components/Card'
import Space from '../../Components/Space'
import NameField, { nameFieldInitialState } from './NameField'
import AddIcon from '@mui/icons-material/Add'
import {
  initialCustomInputState,
  useCustomInput
} from '../../Components/Inputs/CustomInput'
import AddressField from '../../Components/Inputs/AddressInput'
import { useContacts } from '../../Providers/ContactsProvider/ContactsProvider'
import { useWvs } from '../../Providers/WvsProvider/KeeperProvider'

export interface AddContactProps {}

const AddContact: React.FC<AddContactProps> = (props) => {
  const address = useCustomInput()
  const [nameField, setNameField] = React.useState(nameFieldInitialState())
  const { addContact, exists } = useContacts()
  const { publicState } = useWvs()

  const disabled =
    !!address.state.error ||
    !!nameField.error ||
    !address.state.touched ||
    !nameField.touched

  const submit = () => {
    if (!publicState?.network?.code) throw new Error('ChainId missing')

    addContact({
      name: nameField.value,
      address: address.state.value,
      chainId: publicState?.network?.code
    })

    address.setState(initialCustomInputState)
    setNameField(nameFieldInitialState())
  }

  return (
    <Card title="Add contact" maxWidth={450}>
      <NameField state={nameField} setState={setNameField} exists={exists} />
      <Space />
      <AddressField {...address} label="Address" required />
      <Space />
      <Button
        variant="contained"
        fullWidth
        disabled={disabled}
        endIcon={<AddIcon />}
        onClick={submit}
      >
        Add contact
      </Button>
    </Card>
  )
}

export default AddContact
