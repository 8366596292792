import { TRANSACTION_TYPE as TT } from '@waves/ts-types'

export interface TransactionsDictionary {
  readonly [key: number]: string
}

export const transactionsDictionary: TransactionsDictionary = Object.freeze({
  [TT.GENESIS]: 'Genesis',
  [TT.PAYMENT]: 'Payment',
  [TT.ISSUE]: 'Issue Token',
  [TT.TRANSFER]: 'Transfer',
  [TT.REISSUE]: 'Reissue Token',
  [TT.BURN]: 'Burn Token',
  [TT.EXCHANGE]: 'Exchange',
  [TT.LEASE]: 'Lease',
  [TT.CANCEL_LEASE]: 'Cancel Lease',
  [TT.ALIAS]: 'Alias',
  [TT.MASS_TRANSFER]: 'Mass Transfer',
  [TT.DATA]: 'Data',
  [TT.SET_SCRIPT]: 'Set Script',
  [TT.SPONSORSHIP]: 'Sponsorship',
  [TT.SET_ASSET_SCRIPT]: 'Set Asset Script',
  [TT.INVOKE_SCRIPT]: 'Invoke Script',
  [TT.UPDATE_ASSET_INFO]: 'Update Asset Info'
})
