import { IdType, Row } from 'react-table'
import { Transaction } from '../../../Blockchain/BCInterfaces'
import { TimestampRange } from '../../../Components/TxInputs/TimestampRangeSelect'
import { TxOrigin } from '../../../Components/TxInputs/TxOriginSelect'

export type FilterFunction<T extends object, Y> = (
  rows: Row<T>[],
  columnIds: IdType<T>[],
  filterValue: Y
) => Row<T>[]

export interface TxOriginFilterPayload {
  origin: TxOrigin
  address?: string
}

export const typeFilter: FilterFunction<Transaction, number[]> = (
  rows,
  _columnIds,
  filterValue
) => {
  return rows.filter((row) => filterValue.includes(row.original.type))
}

export const dateFilter: FilterFunction<Transaction, TimestampRange> = (
  rows,
  _columnIds,
  filterValue
) => {
  const { from, to } = filterValue

  return rows.filter(
    (row) => row.original.timestamp >= from && row.original.timestamp <= to
  )
}

export const txOriginFilter: FilterFunction<Transaction, TxOriginFilterPayload> = (
  rows,
  _columnIds,
  filterValue
) => {
  if (!filterValue.address) return rows

  if (filterValue.origin === 'outcoming') {
    return rows.filter((row) => row.original.sender === filterValue.address)
  }

  if (filterValue.origin === 'incoming') {
    return rows.filter((row) => row.original.sender !== filterValue.address)
  }

  return rows
}
