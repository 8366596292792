import Card from '../../Components/Card'
import React from 'react'
import { useCustomInput } from '../../Components/Inputs/CustomInput'
import CustomTextarea from '../../Components/Inputs/CustomTextarea'
import TxButton from '../../Components/TxButton'
import { useTransaction } from '../../Providers/WvsProvider/useTransaction'
import { useWvs } from '../../Providers/WvsProvider/KeeperProvider'
import { useTransactionNotification } from '../../Providers/NotificationProvider/useTransactionNotification'
import TxErrorDialog from '../../Components/TxErrorDialog'
import CustomSelect, { CustomSelectOption } from '../../Components/Inputs/CustomSelect'
import { useForm } from '../../Common/useForm'
import IntegerInput from '../../Components/Inputs/IntegerInput'

const decimalsOptions: CustomSelectOption[] = '012345678'
  .split('')
  .map((char) => ({ value: char, label: char }))

const IssueView: React.FC = () => {
  const tx = useTransaction()
  const name = useCustomInput({ required: true })
  const desc = useCustomInput()
  const quantity = useCustomInput('1')
  const decimals = useCustomInput('0')
  const reissuable = useCustomInput('false')
  const { services } = useWvs()
  useTransactionNotification(tx.state.status)

  const { ready } = useForm([
    name.state,
    desc.state,
    quantity.state,
    decimals.state,
    reissuable.state
  ])

  const send = async () => {
    const args = [name, desc, reissuable, quantity, decimals].map(
      (arg) => arg.state.value
    ) as [string, string, string, string, string]

    const params = services.txCreator.createIssueTxData(...args)

    await tx.send(params)
  }

  return (
    <Card title="Issue token" maxWidth={500}>
      <CustomTextarea
        {...name}
        label="Name"
        required
        spaceBottom
        validate={(val) => {
          if (val.length < 4) return 'Minimal length is 4'
          if (val.length > 16) return 'Maximal length is 16'
          return null
        }}
      />
      <CustomTextarea
        {...desc}
        label="Description"
        spaceBottom
        validate={(val) => {
          if (val.length > 1000) return 'Maximal length is 1000'
          return null
        }}
      />
      <CustomSelect
        {...reissuable}
        label="Reissauble"
        spaceBottom
        options={[
          { value: 'true', label: 'True' },
          { value: 'false', label: 'False' }
        ]}
      />
      <CustomSelect
        {...decimals}
        label="Decimals"
        options={decimalsOptions}
        spaceBottom
      />
      <IntegerInput
        {...quantity}
        label="Quantity"
        required
        spaceBottom
        validate={(val) => {
          if (Number(val) < 1) return 'Value has to be greater than 1'
          return null
        }}
      />
      <TxButton
        tx={tx.state}
        onClick={send}
        text="Issue"
        reset={tx.reset}
        wrapper
        disabled={!ready}
      />
      <TxErrorDialog tx={tx.state} reset={tx.reset} />
    </Card>
  )
}

export default IssueView
