import React from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  TablePagination,
  Paper,
  Typography,
  Box
} from '@mui/material'
import { TableInstance } from 'react-table'

export interface TableRendererProps<T extends object = any> {
  instance: TableInstance<T>
  rowsPerPageOptions?: number[]
  component?: 'Paper' | 'Box'
}

const TableRenderer: React.FC<TableRendererProps> = (props) => {
  const { instance } = props

  const component = (props.component ?? 'Box') === 'Paper' ? Paper : Box

  return (
    <TableContainer
      component={component}
      sx={{ padding: '10px', width: 'calc(100% - 20px)' }}
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            {instance.headers.map((column, index) => (
              <TableCell
                {...column.getHeaderProps()}
                align={index === 0 ? 'left' : 'right'}
              >
                <TableSortLabel
                  active={column.isSorted}
                  direction={column.isSortedDesc ? 'desc' : 'asc'}
                  disabled={!column.canSort}
                  onClick={() =>
                    instance.toggleSortBy(
                      column.id,
                      column.isSorted ? !column.isSortedDesc : false
                    )
                  }
                >
                  <b>{column.render('Header')}</b>
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {instance.page.map((row) => {
            instance.prepareRow(row)

            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell, index) => (
                  <TableCell
                    {...cell.getCellProps()}
                    align={index === 0 ? 'left' : 'right'}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '5px'
        }}
      >
        <Typography variant="body2" color="GrayText" sx={{ padding: '10px' }}>
          Total number of items: {instance.preFilteredRows.length}
        </Typography>
        <TablePagination
          count={instance.rows.length}
          rowsPerPage={instance.state.pageSize}
          page={instance.state.pageIndex}
          rowsPerPageOptions={props.rowsPerPageOptions ?? [10, 25, 50, 200]}
          component="div"
          onPageChange={(_, page) => {
            if (page > instance.state.pageIndex) {
              instance.nextPage()
            }

            if (page < instance.state.pageIndex) {
              instance.previousPage()
            }
          }}
          onRowsPerPageChange={(e) => instance.setPageSize(Number(e.target.value))}
          backIconButtonProps={{ disabled: !instance.canPreviousPage }}
          nextIconButtonProps={{ disabled: !instance.canNextPage }}
        />
      </Box>
    </TableContainer>
  )
}

export default TableRenderer
