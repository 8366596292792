import React from 'react'
import { Card, CardActionArea } from '@mui/material'

interface AssetCardProps {
  children: React.ReactNode
  onClick?(): void
}

const AssetCard = ({ children, onClick }: AssetCardProps) => {
  return (
    <Card onClick={onClick} sx={{ maxWidth: 'max-content', float: 'right' }}>
      <CardActionArea sx={{ display: 'flex' }}>{children}</CardActionArea>
    </Card>
  )
}

export default AssetCard
