import React from 'react'
import { ContactsStorageState, useContactsStorage } from './useContactsStorage'

export const ContactsContext = React.createContext<ContactsStorageState>(null as any)

export const ContactsProvider: React.FC = (props) => {
  const state = useContactsStorage()

  return (
    <ContactsContext.Provider value={state}>{props.children}</ContactsContext.Provider>
  )
}

export const useContacts = () => React.useContext(ContactsContext)
