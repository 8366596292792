import React from 'react'
import CustomTextarea, { CustomTextareaProps } from './CustomTextarea'

export interface IntegerInputProps extends CustomTextareaProps {
  positive?: boolean
  range?: [number, number]
}

const validate = (value: string, props: IntegerInputProps): string | null => {
  const num = Number(value)

  if (value === '') {
    return 'Value is not a valid number'
  }

  if (!Number.isFinite(num)) {
    return 'Value is not a valid number'
  }

  if (!Number.isSafeInteger(num)) {
    return 'Number is not a safe integer'
  }

  if (num < 0 && props.positive) {
    return 'Value is negative'
  }

  if (props.range && num < props.range[0]) {
    return `Value has to be higher than ${props.range[0]}`
  }

  if (props.range && num > props.range[1]) {
    return `Value has to be lower than ${props.range[1]}`
  }

  return null
}

const AmountInput: React.FC<IntegerInputProps> = (props) => {
  return (
    <CustomTextarea
      {...props}
      type="number"
      required={props.required}
      disabled={props.disabled}
      label={props.label ?? 'Integer'}
      validate={(val) => validate(val, props)}
    />
  )
}

export default AmountInput
