import React from 'react'
import { inspect } from 'util'

import { NftFilterOptions, initialNftFilterOptions } from '../components/NftFilterController/NftFilterController'
import { AssetData } from '../../../Blockchain/BCInterfaces'
import { useWvs } from '../../../Providers/WvsProvider/KeeperProvider'

interface IContext {
  children: React.ReactNode
}

interface ContextValue {
  filterOptions: NftFilterOptions
  setFilterOptions: React.Dispatch<React.SetStateAction<NftFilterOptions>>
  assets: AssetData[]
  loading: boolean
  error: string | null
  removeAssetData: (assetId: string) => void
}

const EXPLORER_NFTS_PATH = '/explore-nfts'

const ExploreNftsContext = React.createContext<ContextValue>(null as any)

export const ExploreNftsProvider = ({ children }: IContext) => {
  const [filterOptions, setFilterOptions] = React.useState<NftFilterOptions>(initialNftFilterOptions())
  const [assets, setAssets] = React.useState<AssetData[]>([])
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState<string | null>(null)

  const { services } = useWvs()

  const fetchAssetsWithPagination = async () => {
    let doFetch = true
    const limit = 1000
    let after = ''

    setAssets([])
    setLoading(true)

    if (!filterOptions.address) return

    while (doFetch) {
      // eslint-disable-next-line no-loop-func
      const res = await services.readService.getAssetsNft(filterOptions.address, limit, after).catch((e) => {
        console.error(e)
        setError(inspect(e))
        doFetch = false
        throw e
      })

      setAssets((prevState) => [...prevState, ...res])

      if (res.length < limit) {
        doFetch = false
        continue
      }

      after = res[res.length - 1].assetId
    }

    setLoading(false)
  }

  React.useEffect(() => {
    if (window.location.pathname !== EXPLORER_NFTS_PATH) return
    fetchAssetsWithPagination()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions.address, window.location.pathname])

  const removeAssetData = (assetId: string) => {
    const assetToRemove = assets.find((asset) => asset.assetId === assetId)
    if (!assetToRemove) return

    setAssets((prevState) => assets.filter((asset) => asset.assetId !== assetId))
  }

  const contextValue: ContextValue = {
    filterOptions,
    setFilterOptions,
    assets,
    loading,
    error,
    removeAssetData
  }

  return <ExploreNftsContext.Provider value={contextValue}>{children}</ExploreNftsContext.Provider>
}

export const useExploreNfts = (): ContextValue => React.useContext(ExploreNftsContext)
