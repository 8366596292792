import React from 'react'
import { FormControlLabel, FormGroup, Switch, TextField, Typography } from '@mui/material'

import { useSettings } from '../../Providers/SettingsProvider/SettingsProvider'

import Card from '../../Components/Card'
import Space from '../../Components/Space'

const isValidUrl = (url: string) => {
  try {
    new URL(url)
    return true
  } catch {
    return false
  }
}

const SettingsView: React.FC = () => {
  const { darkMode, setDarkMode, servicesUrls, setServicesUrls } = useSettings()

  const handleSetUrlSettings = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setServicesUrls((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))
  }

  return (
    <Card title="Settings" maxWidth={500}>
      <Space />
      <FormGroup sx={{ width: '100%' }}>
        <TextField
          label="Explorer url"
          name="explorerUrl"
          value={servicesUrls.explorerUrl}
          onChange={handleSetUrlSettings}
          helperText={isValidUrl(servicesUrls.explorerUrl) ? undefined : 'Url is not valid'}
          error={!isValidUrl(servicesUrls.explorerUrl)}
        />
        <Space />
        <FormControlLabel
          label="Dark theme"
          labelPlacement="end"
          control={<Switch checked={darkMode} onChange={(e) => setDarkMode(e.target.checked)} />}
        />
        <Space height={50} />

        <Typography component="h2">Assets services</Typography>
        <Space height={30} />

        <TextField
          label="Skey Box Certificates"
          name="skeyBoxAssetServiceUrl"
          value={servicesUrls.skeyBoxAssetServiceUrl}
          onChange={handleSetUrlSettings}
          helperText={isValidUrl(servicesUrls.skeyBoxAssetServiceUrl) ? undefined : 'Url is not valid'}
          error={!isValidUrl(servicesUrls.skeyBoxAssetServiceUrl)}
        />
        <Space height={30} />

        <TextField
          label="Skey Tix IPFS"
          name="skeyTixAssetServiceUrl"
          value={servicesUrls.skeyTixAssetServiceUrl}
          onChange={handleSetUrlSettings}
          helperText={isValidUrl(servicesUrls.skeyTixAssetServiceUrl) ? undefined : 'Url is not valid'}
          error={!isValidUrl(servicesUrls.skeyTixAssetServiceUrl)}
        />
        <Space height={30} />

        <TextField
          label="Go2Nft IPFS"
          name="go2nftAssetServiceUrl"
          value={servicesUrls.go2nftAssetServiceUrl}
          onChange={handleSetUrlSettings}
          helperText={isValidUrl(servicesUrls.go2nftAssetServiceUrl) ? undefined : 'Url is not valid'}
          error={!isValidUrl(servicesUrls.go2nftAssetServiceUrl)}
        />
        <Space height={15} />
      </FormGroup>
    </Card>
  )
}

export default SettingsView
