import React from 'react'
import { Box } from '@mui/system'

export interface SpaceProps {
  height?: number
}

const Space: React.FC<SpaceProps> = (props) => {
  return <Box sx={{ height: `${props.height ?? 20}px` }} />
}

export default Space
