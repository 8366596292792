import React from 'react'

import { Box } from '@mui/material'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment'

import { AssetData } from '../../../../../Blockchain/BCInterfaces'
import { useWvs } from '../../../../../Providers/WvsProvider/KeeperProvider'
import { useExploreNftsModals } from '../../../contexts/ExploreNftsModals.context'

import ActionIcon from '../../../../../Components/ActionIcon'

interface NftActionsProps {
  asset: AssetData
  browsedAddress: string | null
}

const NftActions = ({ asset, browsedAddress }: NftActionsProps) => {
  const { publicState } = useWvs()
  const { openBurnNftModal, openTransferNftModal, selectAsset } = useExploreNftsModals()

  const isMyAssets = React.useMemo(
    () => publicState?.account?.address === browsedAddress,
    [browsedAddress, publicState?.account?.address]
  )

  const handleOpenTransferNftModal = () => {
    openTransferNftModal()
    selectAsset(asset)
  }

  const handleOpenBurnNftModal = () => {
    openBurnNftModal()
    selectAsset(asset)
  }

  return (
    <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
      <ActionIcon
        icon={SwapHorizIcon}
        tooltip="Transfer token"
        color="primary"
        disabled={!isMyAssets}
        onClick={handleOpenTransferNftModal}
      />
      <ActionIcon
        icon={LocalFireDepartmentIcon}
        tooltip="Burn token"
        color="error"
        disabled={!isMyAssets}
        onClick={handleOpenBurnNftModal}
      />
    </Box>
  )
}

export default NftActions
