import { TableCell, TableRow } from '@mui/material'
import ActionIcon from '../../Components/ActionIcon'
import { Contact } from '../../Providers/ContactsProvider/Contact'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import LinkIcon from '@mui/icons-material/Link'
import React from 'react'
import ConfirmationDialog from '../../Components/ConfirmationDialog'
import HashText from '../../Components/HashText'

export interface ContactListItemProps {
  contact: Contact
  deleteHandler: () => void
  explorerUrl?: string
}

const ContactListItem: React.FC<ContactListItemProps> = (props) => {
  const [dialog, setDialog] = React.useState(false)

  const goToExplorer = () => {
    window.open(`${props.explorerUrl}/address/${props.contact.address}`, '_blank')
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>{props.contact.name}</TableCell>
        <TableCell align="right">
          <HashText text={props.contact.address} tooltip explorerPath="/address" />
        </TableCell>
        <TableCell align="right">
          <HashText text={props.contact.chainId} />
        </TableCell>
        <TableCell align="right">
          <ActionIcon
            icon={ContentCopyIcon}
            tooltip="Copy to clipboard"
            onClick={() => window.navigator.clipboard.writeText(props.contact.address)}
            color="primary"
          />
          <ActionIcon
            icon={LinkIcon}
            tooltip="Go to explorer"
            onClick={goToExplorer}
            color="secondary"
            disabled={!props.explorerUrl}
          />
          <ActionIcon
            icon={DeleteIcon}
            tooltip="Delete"
            onClick={() => setDialog(true)}
            color="error"
          />
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        open={dialog}
        setOpen={setDialog}
        callback={props.deleteHandler}
        text="Are you sure you want to delete this contact?"
      />
    </React.Fragment>
  )
}

export default ContactListItem
