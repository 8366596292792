import { Box, Chip, CircularProgress, Typography } from '@mui/material'
import Card from '../../Components/Card'
import Space from '../../Components/Space'
import React from 'react'
import { useCustomInput } from '../../Components/Inputs/CustomInput'
import CustomTextarea from '../../Components/Inputs/CustomTextarea'
import TxButton from '../../Components/TxButton'
import { useTransaction } from '../../Providers/WvsProvider/useTransaction'
import { useWvs } from '../../Providers/WvsProvider/KeeperProvider'
import { useTransactionNotification } from '../../Providers/NotificationProvider/useTransactionNotification'
import TxErrorDialog from '../../Components/TxErrorDialog'
import { useNodeData } from '../../Providers/WvsProvider/useNodeData'

const AliasView: React.FC = () => {
  const tx = useTransaction()
  const alias = useCustomInput()
  const { services } = useWvs()
  useTransactionNotification(tx.state.status)

  const aliasRequest = useNodeData({
    initialValue: [],
    exec: (service, address, chainId) => service.aliases(address, chainId)
  })

  const send = async () => {
    const params = services.txCreator.createAliasTxData(alias.state.value)
    await tx.send(params)
    aliasRequest.forceFetch()
  }

  return (
    <React.Fragment>
      <Card title="New alias" maxWidth={500}>
        <CustomTextarea
          {...alias}
          label="Alias"
          required
          validate={(val) =>
            val.includes(' ') ? 'Alias cannot include white spaces' : null
          }
        />
        <Space />
        <TxButton
          tx={tx.state}
          reset={tx.reset}
          onClick={send}
          text="Set alias"
          disabled={!!alias.state.error || !alias.state.touched}
          wrapper
        />
        <TxErrorDialog tx={tx.state} reset={tx.reset} />
      </Card>
      <Card title="Current aliases" maxWidth={500} sx={{ marginLeft: '15px' }}>
        <Box sx={{ width: '100%' }}>
          {aliasRequest.data.map((alias) => (
            <Chip key={alias} label={alias} variant="outlined" sx={{ margin: '4px' }} />
          ))}
          {!aliasRequest.data.length && !aliasRequest.error && !aliasRequest.loading && (
            <Typography>No aliases</Typography>
          )}
          {aliasRequest.loading && <CircularProgress sx={{ margin: '25px 0' }} />}
          {aliasRequest.error}
        </Box>
      </Card>
    </React.Fragment>
  )
}

export default AliasView
