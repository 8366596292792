import React from 'react'
import { CircularProgress, Typography } from '@mui/material'

import { ExploreNftsModalsProvider } from './contexts/ExploreNftsModals.context'
import { useExploreNfts } from './contexts/ExploreNfts.context'

import Card from '../../Components/Card'
import Space from '../../Components/Space'

import NftFilterController from './components/NftFilterController/NftFilterController'
import NftTable from './components/NftTable/NftTable'
import TransferNftModal from './components/ExploreNftModals/TransferNftModal'
import BurnNftModal from './components/ExploreNftModals/BurnNftModal'

// 3EDCDALd9frXcrFstu2GuKtChDrnVrQRL7o

const ExploreNfts = () => {
  const { filterOptions, setFilterOptions, assets, loading, error } = useExploreNfts()

  const noAssets = !assets.length && !error && !loading

  return (
    <ExploreNftsModalsProvider>
      <Card title="Explore NFT" maxWidth={1200} sx={{ marginBottom: '20px' }}>
        <NftFilterController options={filterOptions} setOptions={setFilterOptions} />
        <Space height={15} />

        {loading && <CircularProgress sx={{ margin: '20px 0' }} />}
        {error && <Typography>{error}</Typography>}
        {noAssets && <Typography sx={{ margin: '20px 0' }}>No nft assets.</Typography>}

        {!!assets.length && <NftTable assets={assets} options={filterOptions} />}
      </Card>

      <TransferNftModal />
      <BurnNftModal />
    </ExploreNftsModalsProvider>
  )
}

export default ExploreNfts
