import React from 'react'
import Card from '../../Components/Card'
import { useCustomInput } from '../../Components/Inputs/CustomInput'
import AddressInput from '../../Components/Inputs/AddressInput'
import AmountInput from '../../Components/Inputs/AmountInput'
import { useTransaction } from '../../Providers/WvsProvider/useTransaction'
import TxButton from '../../Components/TxButton'
import { useContacts } from '../../Providers/ContactsProvider/ContactsProvider'
import { useForm } from '../../Common/useForm'
import { useWvs } from '../../Providers/WvsProvider/KeeperProvider'
import { useTransactionNotification } from '../../Providers/NotificationProvider/useTransactionNotification'
import TxErrorDialog from '../../Components/TxErrorDialog'

export interface LeaseFormProps {
  forceUpdate: () => void
}

const LeaseForm: React.FC<LeaseFormProps> = (props) => {
  const address = useCustomInput({ required: true })
  const amount = useCustomInput({ required: true })
  const { ready } = useForm([address.state, amount.state])
  const tx = useTransaction()
  const { availableContacts } = useContacts()
  const { services } = useWvs()
  useTransactionNotification(tx.state.status)

  const send = async () => {
    const num = Number(amount.state.value) * 10 ** 8

    const params = services.txCreator.createLeaseTxData(
      address.state.value,
      num.toString()
    )
    await tx.send(params)
    props.forceUpdate()
  }

  return (
    <Card title="Lease" maxWidth={500}>
      <AddressInput
        {...address}
        label="Recipient"
        spaceBottom
        contacts={availableContacts}
        required
      />
      <AmountInput
        {...amount}
        spaceBottom
        denomination="coin"
        required
        label="Amount (coins)"
      />
      <TxButton
        tx={tx.state}
        text="Lease"
        onClick={send}
        reset={tx.reset}
        wrapper
        disabled={!ready}
      />
      <TxErrorDialog tx={tx.state} reset={tx.reset} />
    </Card>
  )
}

export default LeaseForm
