import { CircularProgress, Typography } from '@mui/material'
import React from 'react'
import Card from '../../Components/Card'
import Space from '../../Components/Space'
import TxButton from '../../Components/TxButton'
import { useTransaction } from '../../Providers/WvsProvider/useTransaction'
import { useWvs } from '../../Providers/WvsProvider/KeeperProvider'
import CustomSelect from '../../Components/Inputs/CustomSelect'
import { useCustomInput } from '../../Components/Inputs/CustomInput'
import CustomTextarea from '../../Components/Inputs/CustomTextarea'
import { useTransactionNotification } from '../../Providers/NotificationProvider/useTransactionNotification'
import { useNodeData } from '../../Providers/WvsProvider/useNodeData'

// Action types => remove, custom, select
// Script types => supplier, organisation, device, dappFather

const fetchScript = async (key: string): Promise<string | null> => {
  const url =
    'https://raw.githubusercontent.com/skey-network/skey-client-config/master/dapps.json'

  const scriptsInfo = await fetch(url)
    .then((res) => res.json())
    .catch((err) => console.error(err))

  const scriptUrl = scriptsInfo?.scripts?.[key]?.url
  if (!scriptUrl) return null

  return await fetch(scriptUrl)
    .then((res) => res.text())
    .catch((err) => {
      console.error(err)
      return null
    })
}

const ScriptView: React.FC = () => {
  const { services } = useWvs()
  const customScript = useCustomInput()
  const selectedScript = useCustomInput('supplier')
  const scriptHashRequest = useNodeData({
    initialValue: null,
    exec: (service, address) => service.getScriptHash(address)
  })
  const tx = useTransaction()
  const action = useCustomInput('select')
  useTransactionNotification(tx.state.status)

  const send = async () => {
    if (action.state.value === 'select') {
      const script = await fetchScript(selectedScript.state.value)

      if (!script) {
        console.error('Failed to fetch script')
        return
      }

      const params = await services.txCreator.createSetScriptTxData(script)
      await tx.send(params)
    }

    if (action.state.value === 'custom') {
      const withPrefix = 'base64:' + customScript.state.value.replace('base64:', '')

      const params = await services.txCreator.createSetScriptTxData(withPrefix)
      await tx.send(params)
    }

    if (action.state.value === 'remove') {
      const params = await services.txCreator.createSetScriptTxData(null)
      await tx.send(params)
    }

    services.feeCalculator.setScriptCache(action.state.value !== 'remove')
    scriptHashRequest.forceFetch()
  }

  return (
    <Card title="Script">
      <Typography sx={{ width: '100%' }} variant="body2" gutterBottom>
        Current script hash:
      </Typography>
      <Typography
        sx={{
          width: '100%',
          color: '#999',
          fontFamily: 'monospace',
          wordBreak: 'break-word'
        }}
        variant="caption"
      >
        {scriptHashRequest.loading && (
          <CircularProgress size={20} style={{ marginTop: '5px' }} />
        )}
        {scriptHashRequest.loading ? null : scriptHashRequest.data ?? 'null'}
      </Typography>
      <Space />
      <CustomSelect
        {...action}
        label="Action"
        options={[
          { value: 'select', label: 'Set known script' },
          { value: 'remove', label: 'Remove script' },
          { value: 'custom', label: 'Set custom script' }
        ]}
      />
      {action.state.value === 'select' && (
        <React.Fragment>
          <Space />
          <CustomSelect
            {...selectedScript}
            label="Selected script"
            options={[
              { value: 'supplier', label: 'Supplier' },
              { value: 'organisation', label: 'Organisation' },
              { value: 'device', label: 'Device' },
              { value: 'father', label: 'Dapp father' }
            ]}
          />
        </React.Fragment>
      )}
      {action.state.value === 'custom' && (
        <React.Fragment>
          <Space />
          <CustomTextarea
            {...customScript}
            rows={6}
            size="small"
            multiline
            placeholder="base64:"
            label="Script"
          />
        </React.Fragment>
      )}
      <Space />
      <TxButton tx={tx.state} onClick={send} text="Set Script" reset={tx.reset} wrapper />
    </Card>
  )
}

export default ScriptView
