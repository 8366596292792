import React from 'react'
import { CustomInputState } from './CustomInput'
import CustomTextarea from './CustomTextarea'

export interface AssetIdInputProps {
  state: CustomInputState
  setState: (state: CustomInputState) => void
  disabled?: boolean
  required?: boolean
  spaceBottom?: boolean
  spaceTop?: boolean
  label?: string
}

const validate = (value: string): string | null => {
  const regex = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/

  if (value.length < 32) return 'AssetId is too short (min 32 characters)'

  if (value.length > 44) return 'AssetId is too long (max 44 characters)'

  if (!regex.test(value)) return 'AssetId is not valid'

  return null
}

const AssetIdInput: React.FC<AssetIdInputProps> = (props) => {
  return (
    <CustomTextarea
      {...props}
      label={props.label ?? 'AssetId'}
      validate={(val) => validate(val)}
      spaceBottom={props.spaceBottom}
      spaceTop={props.spaceTop}
    />
  )
}

export default AssetIdInput
