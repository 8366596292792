import { createTheme, ThemeOptions } from '@mui/material/styles'
import { ThemeProvider as RawThemeProvider } from '@mui/system'
import { useSettings } from '../SettingsProvider/SettingsProvider'

export const themeOptions = (darkMode: boolean): ThemeOptions => ({
  palette: {
    mode: darkMode ? 'dark' : 'light',
    primary: {
      main: '#5c44ec'
    },
    secondary: {
      main: '#eca344'
    },
    background: {
      default: darkMode ? '#1a1a1a' : '#e7e7e7',
      paper: darkMode ? '#1a1a1a' : '#f7f7f7'
    }
  },
  typography: {
    fontSize: 16
  }
})

const ThemeProvider: React.FC = (props) => {
  const { darkMode } = useSettings()
  const theme = createTheme(themeOptions(darkMode))

  return <RawThemeProvider theme={theme}>{props.children}</RawThemeProvider>
}

export default ThemeProvider
