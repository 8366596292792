import * as Transactions from '@waves/waves-transactions'
import { WalletStatus } from './BCInterfaces'

export class BCKeeperService {
  private publicState?: WavesKeeper.IPublicStateResponse

  public update(publicState?: WavesKeeper.IPublicStateResponse) {
    this.publicState = publicState
  }

  get keeper(): WavesKeeper.TWavesKeeperApi | null {
    const win = window as any

    if (win.SkeyKeeper) return win.SkeyKeeper
    if (win.WavesKeeper) return win.WavesKeeper
    return null
  }

  async waitForTx(txId: string) {
    if (!this.keeper || !this.publicState) {
      console.error('cannot wait for tx')
      await this.delay(1000)
      return
    }

    await Transactions.waitForTx(txId, { apiBase: this.publicState.network?.server })
  }

  delay(timeout: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout)
    })
  }

  async connect(
    setStatus: (status: WalletStatus) => void,
    setPublicState: (state: WavesKeeper.IPublicStateResponse | null) => void
  ) {
    if (!this.keeper) {
      console.error('keeper not available')
      return false
    }

    try {
      const result = await this.keeper.publicState()
      if (!result) return false

      setStatus(result ? 'connected' : 'refused')
      setPublicState(result)

      this.keeper?.on('update', setPublicState)

      return true
    } catch (err: any) {
      console.error(err)
      if (Number(err?.code) === 14) setStatus('no_account')
      else if (Number(err?.code) === 10) setStatus('refused')
      else setStatus('not_available')

      console.log('updated', err?.code)

      return false
    }
  }

  // Returns true if keeper is ready
  // and false if timeout was reached
  waitForKeeper(timeout: number) {
    const interval = 100
    const start = Date.now()

    return new Promise<boolean>((resolve) => {
      const handle = setInterval(async () => {
        if (this.keeper?.initialPromise) {
          await this.keeper.initialPromise

          clearInterval(handle)
          return resolve(true)
        }

        const timeDiff = Date.now() - start

        if (timeDiff >= timeout) {
          clearInterval(handle)
          return resolve(false)
        }
      }, interval)
    })
  }
}
