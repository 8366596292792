import React from 'react'
import { Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import type { TransactionState } from '../Providers/WvsProvider/useTransaction'

export interface TxButtonProps {
  tx: TransactionState
  onClick: () => void
  reset: () => void
  text?: string
  disabled?: boolean
  wrapper?: boolean
}

const getText = (status: TransactionState['status'], label?: string) => {
  switch (status) {
    case 'ready':
      return label ?? 'Send'
    case 'pending':
      return 'Pending'
    case 'waiting':
      return 'Sending'
    case 'success':
      return 'Completed'
    case 'failed':
      return 'Failed'
    case 'rejected':
      return 'Rejected'
    default:
      return 'Error'
  }
}

const isLoading = (status: TransactionState['status']) => {
  return status === 'pending' || status === 'waiting'
}

const isArrow = (status: TransactionState['status']) => {
  return status === 'ready' || status === 'waiting' || status === 'pending'
}

const TxButton: React.FC<TxButtonProps> = (props) => {
  React.useEffect(() => {
    const { status } = props.tx

    if (status === 'success' || status === 'rejected') {
      const handle = setTimeout(() => {
        props.reset()
      }, 800)

      return () => clearTimeout(handle)
    }

    // eslint-disable-next-line
  }, [props.reset, props.tx.status])

  const button = (
    <LoadingButton
      variant="contained"
      loading={isLoading(props.tx.status)}
      loadingPosition={isArrow(props.tx.status) ? 'end' : undefined}
      endIcon={isArrow(props.tx.status) ? <ArrowForwardIcon /> : undefined}
      onClick={props.onClick}
      disabled={props.disabled || props.tx.status !== 'ready'}
    >
      {getText(props.tx.status, props.text)}
    </LoadingButton>
  )

  if (!props.wrapper) return button

  return <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>{button}</Box>
}

export default TxButton
