import moment from 'moment'
import { Chip, Box } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ActionIcon from '../../../Components/ActionIcon'
import { Transaction } from '../../../Blockchain/BCInterfaces'
import React from 'react'
import { transactionsDictionary } from '../TransactionConstants'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import HashText from '../../../Components/HashText'
import { CellProps } from 'react-table'

export const TransactionId: React.FC<CellProps<Transaction, any>> = (props) => {
  const sender: string | null =
    props.state.filters.find((filter) => filter.id === 'sender')?.value.address ?? null

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {sender === props.row.original.sender && (
        <ArrowUpwardIcon fontSize="small" color="success" sx={{ marginRight: '10px' }} />
      )}
      {!!sender && sender !== props.row.original.sender && (
        <ArrowDownwardIcon fontSize="small" color="info" sx={{ marginRight: '10px' }} />
      )}
      <HashText text={props.row.original.id} explorerPath="/tx" tooltip />
    </Box>
  )
}

export const TransactionDate: React.FC<CellProps<Transaction, any>> = (props) => {
  const tx = props.row.original

  return <span>{moment(tx.timestamp).fromNow()}</span>
}

export const TransactionType: React.FC<CellProps<Transaction, any>> = (props) => {
  const tx = props.row.original

  return (
    <Chip
      label={transactionsDictionary[tx.type] ?? 'Unknown'}
      color="secondary"
      size="small"
      variant="outlined"
    />
  )
}

export const TransactionActions: React.FC<CellProps<Transaction, any>> = (props) => {
  const tx = props.row.original

  const copy = () => window.navigator.clipboard.writeText(tx.id)

  return (
    <ActionIcon
      icon={ContentCopyIcon}
      tooltip="Copy to clipboard"
      onClick={copy}
      color="primary"
    />
  )
}
