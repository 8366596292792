import React from 'react'
import Card from '../../Components/Card'
import { CircularProgress, Typography } from '@mui/material'
import TransactionsTable from './Table/TransactionsTable'
import FilterController, { initialFilterOptions } from './FilterController'
import Space from '../../Components/Space'
import { useNodeData } from '../../Providers/WvsProvider/useNodeData'

const TransactionsView: React.FC = () => {
  const [filterOptions, setFilterOptions] = React.useState(initialFilterOptions())

  const txRequest = useNodeData({
    initialValue: [],
    exec: async (service) => {
      if (!filterOptions.address) return []

      return service.allTransactions(filterOptions.address, 1000, 50)
    }
  })

  React.useEffect(() => {
    txRequest.forceFetch()

    // eslint-disable-next-line
  }, [filterOptions.address])

  return (
    <Card title="Transactions" maxWidth={1200} sx={{ marginBottom: '20px' }}>
      <FilterController options={filterOptions} setOptions={setFilterOptions} />
      <Space height={15} />
      {txRequest.loading && <CircularProgress sx={{ margin: '20px 0' }} />}
      {txRequest.error && <Typography>{txRequest.error}</Typography>}
      {!txRequest.data.length && !txRequest.error && !txRequest.loading && (
        <Typography sx={{ margin: '20px 0' }}>No transactions.</Typography>
      )}
      {!!txRequest.data.length && (
        <TransactionsTable transactions={txRequest.data} options={filterOptions} />
      )}
    </Card>
  )
}

export default TransactionsView
