import React from 'react'
import { TransactionState } from '../WvsProvider/useTransaction'
import { useNotifications } from '../NotificationProvider/NotificationProvider'

export const useTransactionNotification = (status: TransactionState['status']) => {
  const { notify } = useNotifications()

  React.useEffect(() => {
    if (status === 'success') notify('Transaction successful', 'success')
    if (status === 'failed') notify('Transaction failed', 'error')
    if (status === 'rejected') notify('Transaction rejectd', 'warning')

    // eslint-disable-next-line
  }, [status])
}
