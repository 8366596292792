import { BCFeeCalculator } from './BCFeeCalculator'
import { BCKeeperService } from './BCKeeperService'
import { BCReadService } from './BCReadService'
import { BCTxCreator } from './BCTxCreator'

export interface BCServices {
  readService: BCReadService
  txCreator: BCTxCreator
  feeCalculator: BCFeeCalculator
  keeperService: BCKeeperService
}

export class BCServiceManager {
  public readService = new BCReadService()
  public txCreator = new BCTxCreator()
  public feeCalculator = new BCFeeCalculator(this.readService)
  public keeperService = new BCKeeperService()

  public getServices(): BCServices {
    return {
      readService: this.readService,
      txCreator: this.txCreator,
      feeCalculator: this.feeCalculator,
      keeperService: this.keeperService
    }
  }

  public update(publicState?: WavesKeeper.IPublicStateResponse) {
    this.keeperService.update(publicState)
    this.readService.update(publicState?.network?.server)
    this.feeCalculator.update(publicState?.account?.address)
  }
}
