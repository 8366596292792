import { Row } from 'react-table'

export const shortenHash = (hash: string, n = 6, separator = '...') => {
  if (!hash?.length || hash.length < 2 * n) return hash

  return hash.slice(0, n) + separator + hash.slice(-n)
}

export const shortenText = (text: string, n = 50, suffix = '...') => {
  if (text.length <= n) return text

  return text.substring(0, n) + suffix
}

export const randHash = () => Math.random().toString(16).slice(2)

export const breakText = (text: string, n: number) => {
  if (!text || !n) return ''
  if (text.length <= n) return text

  const chunksNumber = Math.ceil(text.length / n)
  const chunks: string[] = Array(chunksNumber)

  for (let i = 0; i < chunksNumber; i++) {
    const chunk = text.substring(i * n, (i + 1) * n)
    chunks[i] = chunk
  }

  return chunks.join(' ')
}

export const typeFilterFactory = <T extends object = any>(columnId: keyof T) => {
  return (rows: Row<T>[], _columnIds: string[], filterValue: any[]) =>
    rows.filter((row) => filterValue.includes(row.original[columnId]))
}
