import React from 'react'
import { AssetData } from '../../../Blockchain/BCInterfaces'

interface IContext {
  children: React.ReactNode
}

interface ContextValue {
  selectedAsset: AssetData | null
  selectAsset: (asset: AssetData) => void
  removeSelectedAsset: () => void

  burnNftModalOpened: boolean
  openBurnNftModal: () => void
  closeBurnNftModal: () => void

  transferNftModalOpened: boolean
  openTransferNftModal: () => void
  closeTransferNftModal: () => void
}

const ExploreNftsModalsContext = React.createContext<ContextValue>(null as any)

export const ExploreNftsModalsProvider = ({ children }: IContext) => {
  // Selected asset state
  const [selectedAsset, setSelectedAsset] = React.useState<AssetData | null>(null)
  const selectAsset = (asset: AssetData) => setSelectedAsset(asset)
  const removeSelectedAsset = () => setSelectedAsset(null)

  // Modals state
  const [burnNftModalOpened, setBurnNftModalOpened] = React.useState(false)
  const openBurnNftModal = () => setBurnNftModalOpened(true)
  const closeBurnNftModal = () => setBurnNftModalOpened(false)

  const [transferNftModalOpened, setTransferNftModalOpened] = React.useState(false)
  const openTransferNftModal = () => setTransferNftModalOpened(true)
  const closeTransferNftModal = () => setTransferNftModalOpened(false)

  const contextValue: ContextValue = {
    selectedAsset,
    selectAsset,
    removeSelectedAsset,

    burnNftModalOpened,
    openBurnNftModal,
    closeBurnNftModal,

    transferNftModalOpened,
    openTransferNftModal,
    closeTransferNftModal
  }

  return <ExploreNftsModalsContext.Provider value={contextValue}>{children}</ExploreNftsModalsContext.Provider>
}

export const useExploreNftsModals = (): ContextValue => React.useContext(ExploreNftsModalsContext)
