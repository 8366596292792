import { Paper, Typography } from '@mui/material'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import BackupIcon from '@mui/icons-material/Backup'

export interface DropzoneProps {
  state: string
  setState: React.Dispatch<React.SetStateAction<string>>
  elevation?: number
  fullWidth?: boolean
  disabled?: boolean
}

export const fileToPlainText = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result?.toString() ?? '')
    reader.onerror = () => reject('File parsing failed')
    reader.readAsText(file)
  })
}

const Dropzone: React.FC<DropzoneProps> = (props) => {
  const onDrop = React.useCallback(async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) return

    const content = await fileToPlainText(acceptedFiles[0]).catch((err) => {
      console.error(err)
      return ''
    })

    props.setState(content)

    // eslint-disable-next-line
  }, [])

  const dropzone = useDropzone({
    onDrop,
    disabled: props.disabled,
    multiple: false
  })

  return (
    <Paper
      {...dropzone.getRootProps()}
      sx={{
        width: '100%',
        minWidth: '250px',
        maxWidth: props.fullWidth ? '100%' : '400px',
        height: '120px',
        '&:hover': { cursor: props.disabled ? 'auto' : 'pointer' },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
      elevation={props.elevation}
    >
      <input {...dropzone.getInputProps()} />
      <Typography color="GrayText">
        <BackupIcon sx={{ margin: 'auto', width: '100%', fontSize: '35px' }} />
        <br />
        {props.disabled
          ? 'Disabled'
          : dropzone.isDragActive
          ? 'Drop files here'
          : 'Drag and drop text files here'}
      </Typography>
    </Paper>
  )
}

export default Dropzone
