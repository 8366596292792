import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField
} from '@mui/material'
import React from 'react'
import AddressInput from '../../Components/Inputs/AddressInput'
import { useCustomInput } from '../../Components/Inputs/CustomInput'
import Space from '../../Components/Space'
import { useContacts } from '../../Providers/ContactsProvider/ContactsProvider'
import { useWvs } from '../../Providers/WvsProvider/KeeperProvider'

const availableTypes = Object.freeze(['string', 'boolean', 'integer', 'binary'])

export interface FilterOptions {
  address: string
  key: string
  value: string
  types: string[]
}

export interface FilterControllerProps {
  options: FilterOptions
  setOptions: React.Dispatch<React.SetStateAction<FilterOptions>>
}

export const initialFilterOptions = (): FilterOptions => ({
  address: '',
  key: '',
  value: '',
  types: [...availableTypes]
})

const FilterController: React.FC<FilterControllerProps> = ({ options, setOptions }) => {
  const { publicState, status } = useWvs()
  const { availableContacts } = useContacts()
  const address = useCustomInput(publicState?.account?.address)

  React.useEffect(() => {
    if (status !== 'connected' || !publicState?.account?.address) return

    address.setState({ ...address.state, value: publicState?.account?.address ?? '' })

    // eslint-disable-next-line
  }, [status, publicState])

  React.useEffect(() => {
    setOptions((options) => ({ ...options, address: address.state.value }))

    // eslint-disable-next-line
  }, [address.state.value])

  const renderCheckbox = (type: string) => (
    <FormControlLabel
      key={type}
      label={type}
      sx={{ display: 'block', height: '35px' }}
      control={
        <Checkbox
          checked={options.types.includes(type)}
          onChange={(e) => {
            if (e.target.checked) {
              setOptions({ ...options, types: [...options.types, type] })
            } else {
              setOptions({
                ...options,
                types: options.types.filter((t) => t !== type)
              })
            }
          }}
        />
      }
    />
  )

  return (
    <Accordion
      sx={{
        minWidth: '250px',
        width: '100%',
        marginBottom: '0px !important'
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>Filter options</AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Space height={10} />
            <AddressInput
              {...address}
              label="Address"
              contacts={availableContacts}
              size="small"
            />
            <Space height={15} />
            <TextField
              size="small"
              label="Key"
              value={options.key}
              fullWidth
              onChange={(e) => setOptions({ ...options, key: e.target.value })}
            />
            <Space height={15} />
            <TextField
              size="small"
              label="Value"
              value={options.value}
              fullWidth
              onChange={(e) => setOptions({ ...options, value: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {availableTypes.map(renderCheckbox)}
          </Grid>
        </Grid>
        <Space />
      </AccordionDetails>
    </Accordion>
  )
}

export default FilterController
