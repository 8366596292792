import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import Card from '../../Components/Card'
import React from 'react'
import HashText from '../../Components/HashText'
import ActionIcon from '../../Components/ActionIcon'
import ContentCopy from '@mui/icons-material/ContentCopy'
import { LeaseInfo } from '../../Blockchain/BCInterfaces'

export interface LeaseListProps {
  leases: LeaseInfo[]
  loading: boolean
  error: string | null
}

const LeaseList: React.FC<LeaseListProps> = (props) => {
  if (props.loading)
    return (
      <Card title="Active leases">
        <CircularProgress size={40} sx={{ margin: '20px 0' }} />
      </Card>
    )

  if (props.error)
    return (
      <Card title="Active leases">
        <Typography>{props.error}</Typography>
      </Card>
    )

  return (
    <Card title="Active leases" maxWidth={600}>
      {!!props.leases.length && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow sx={{ '& th, & td': { fontWeight: 700 } }}>
                <TableCell>Id</TableCell>
                <TableCell align="right">Recipient</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.leases.map((lease) => (
                <TableRow key={lease.id}>
                  <TableCell>
                    <HashText text={lease.id} tooltip explorerPath="/tx" />
                  </TableCell>
                  <TableCell align="right">
                    <HashText text={lease.recipient} tooltip explorerPath="/address" />
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ color: '#999', fontFamily: 'monospace' }}
                  >
                    {((lease.amount ?? 0) / 10 ** 8).toFixed(4)}
                  </TableCell>
                  <TableCell align="right">
                    <ActionIcon
                      icon={ContentCopy}
                      tooltip="Copy id"
                      color="primary"
                      onClick={() => window.navigator.clipboard.writeText(lease.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!props.leases.length && (
        <Typography sx={{ margin: '10px 0' }}>No active leases.</Typography>
      )}
    </Card>
  )
}

export default LeaseList
