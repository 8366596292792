import React from 'react'
import { DataEntry } from '../../Blockchain/BCInterfaces'
import {
  useTable,
  Column,
  useSortBy,
  usePagination,
  useFilters,
  TableState
} from 'react-table'
import { FilterOptions } from './FilterController'
import TableRenderer from '../../Components/TableRenderer'
import { typeFilterFactory } from '../../Common/Utils'

export interface DataEntriesProps {
  entries: DataEntry[]
  filterOptions: FilterOptions
}

export const columnsSchema: Column<DataEntry>[] = [
  {
    Header: 'Key',
    accessor: 'key',
    filter: 'text',
    Cell: (arg) => (
      <span style={{ wordBreak: 'break-all', fontFamily: 'monospace' }}>
        {arg.row.original.key}
      </span>
    )
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: (arg) => <span style={{ wordBreak: 'normal' }}>{arg.row.original.type}</span>,
    filter: typeFilterFactory('type')
  },
  {
    Header: 'Value',
    accessor: 'value',
    filter: 'text',
    Cell: (arg) => (
      <span style={{ wordBreak: 'break-all', fontFamily: 'monospace' }}>
        {arg.row.original.value.toString()}
      </span>
    )
  }
]

const initialTableState = (): Partial<TableState<DataEntry>> => ({
  sortBy: [{ id: 'key', desc: false }],
  pageSize: 25
})

const DataEntriesTable: React.FC<DataEntriesProps> = (props) => {
  // eslint-disable-next-line
  const columns = React.useMemo(() => columnsSchema, [])
  // eslint-disable-next-line
  const data = React.useMemo(() => props.entries, [props.entries])
  // eslint-disable-next-line
  const initialState = React.useMemo(() => initialTableState(), [])

  const instance = useTable(
    { columns, data, initialState },
    useFilters,
    useSortBy,
    usePagination
  )

  React.useEffect(() => {
    instance.setAllFilters([
      { id: 'key', value: props.filterOptions.key },
      { id: 'value', value: props.filterOptions.value },
      { id: 'type', value: props.filterOptions.types }
    ])

    // eslint-disable-next-line
  }, [props.filterOptions])

  return <TableRenderer instance={instance} component="Paper" />
}

export default DataEntriesTable
