import React from 'react'
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import { availableTypes, FType } from './FunctionsInterfaces'

export interface FunctionInputProps {
  index: number
  value: string
  setValue: (value: string) => void
  type: FType
  label: string
}

const FunctionInput: React.FC<FunctionInputProps> = (props) => {
  if (props.type === 'Boolean')
    return (
      <FormControl fullWidth>
        <InputLabel id={`fbool_${props.index}`}>{props.label}</InputLabel>
        <Select
          labelId={`fbool_${props.index}`}
          value={props.value}
          onChange={(e) => props.setValue(e.target.value)}
          fullWidth
          label={props.label}
          sx={{ marginBottom: '20px' }}
        >
          <MenuItem value="true">True</MenuItem>
          <MenuItem value="false">False</MenuItem>
        </Select>
      </FormControl>
    )

  return (
    <TextField
      value={props.value}
      onChange={(e) => props.setValue(e.target.value)}
      fullWidth
      label={props.label}
      type={props.type === 'Int' ? 'number' : 'text'}
      sx={{ marginBottom: '20px' }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {availableTypes.includes(props.type) ? props.type : 'N/A'}
          </InputAdornment>
        )
      }}
      disabled={!availableTypes.includes(props.type)}
    />
  )
}

export default FunctionInput
