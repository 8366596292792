import React from 'react'

import { BCServiceManager, BCServices } from '../../Blockchain/BCServiceManager'
import { WalletStatus } from '../../Blockchain/BCInterfaces'
import { useSettings } from '../SettingsProvider/SettingsProvider'

export interface WvsProviderProps {
  connectOnLoad: boolean
}

export interface WvsState {
  status: WalletStatus
  publicState: WavesKeeper.IPublicStateResponse | null
  services: BCServices
  keeper: WavesKeeper.TWavesKeeperApi | null
  connect: () => Promise<boolean>
}

export const WvsContext = React.createContext<WvsState>(null as any)

export const serviceManager = new BCServiceManager()

export const WvsProvider: React.FC<WvsProviderProps> = (props) => {
  const [status, setStatus] = React.useState<WalletStatus>('loading')
  const [publicState, setPublicState] = React.useState<WavesKeeper.IPublicStateResponse | null>(null)

  const { setNetwork } = useSettings()

  React.useEffect(() => {
    if (publicState?.account?.network === 'testnet') {
      setNetwork('testnet')
    } else {
      setNetwork('mainnet')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicState?.account?.network])

  React.useEffect(() => {
    serviceManager.update(publicState ?? undefined)
  }, [publicState])

  const services = serviceManager.getServices()

  React.useEffect(() => {
    services.keeperService.waitForKeeper(2000).then((result) => {
      setStatus(result ? 'available' : 'not_available')
      if (props.connectOnLoad) services.keeperService.connect(setStatus, setPublicState)
    })

    // eslint-disable-next-line
  }, [])

  const state: WvsState = {
    status,
    publicState,
    keeper: services.keeperService.keeper,
    services,
    connect: () => services.keeperService.connect(setStatus, setPublicState)
  }

  return <WvsContext.Provider value={state}>{props.children}</WvsContext.Provider>
}

export const useWvs = () => React.useContext(WvsContext)
