import React from 'react'
import { TableState, useFilters, usePagination, useSortBy, useTable } from 'react-table'
import { Transaction } from '../../../Blockchain/BCInterfaces'
import TableRenderer from '../../../Components/TableRenderer'
import { useWvs } from '../../../Providers/WvsProvider/KeeperProvider'
import { FilterOptions } from '../FilterController'
import { columnsSchema } from './TableColumns'

export const initialTableState = (): Partial<TableState<Transaction>> => ({
  sortBy: [{ id: 'id', desc: false }],
  pageSize: 25
})

export interface TransactionsTableProps {
  transactions: Transaction[]
  options: FilterOptions
}

const TransactionsTable: React.FC<TransactionsTableProps> = (props) => {
  const { transactions, options } = props

  // eslint-disable-next-line
  const columns = React.useMemo(() => columnsSchema, [])
  // eslint-disable-next-line
  const data = React.useMemo(() => transactions, [props.transactions])
  // eslint-disable-next-line
  const initialState = React.useMemo(() => initialTableState(), [])

  const { publicState } = useWvs()

  const instance = useTable(
    { columns, data, initialState },
    useFilters,
    useSortBy,
    usePagination
  )

  React.useEffect(() => {
    instance.setAllFilters([
      { id: 'type', value: options.types },
      {
        id: 'sender',
        value: { origin: options.origin, address: options.address }
      },
      { id: 'timestamp', value: options.timestampRange }
    ])

    // eslint-disable-next-line
  }, [options, publicState])

  return <TableRenderer instance={instance} component="Paper" />
}

export default TransactionsTable
