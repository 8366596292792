import Card from '../../Components/Card'
import React from 'react'
import { useCustomInput } from '../../Components/Inputs/CustomInput'
import CustomTextarea from '../../Components/Inputs/CustomTextarea'
import TxButton from '../../Components/TxButton'
import { useTransaction } from '../../Providers/WvsProvider/useTransaction'
import { useWvs } from '../../Providers/WvsProvider/KeeperProvider'
import { useTransactionNotification } from '../../Providers/NotificationProvider/useTransactionNotification'
import TxErrorDialog from '../../Components/TxErrorDialog'
import { useForm } from '../../Common/useForm'

const BurnView: React.FC = () => {
  const tx = useTransaction()
  const assetId = useCustomInput({ required: true })
  const amount = useCustomInput('1')
  const { services } = useWvs()
  useTransactionNotification(tx.state.status)
  const { ready } = useForm([assetId.state, amount.state])

  const send = async () => {
    const params = services.txCreator.createBurnTxData(
      assetId.state.value,
      Number(amount.state.value)
    )

    await tx.send(params)
  }

  return (
    <Card title="Burn" maxWidth={500}>
      <CustomTextarea {...assetId} label="Asset id" required spaceBottom />
      <CustomTextarea {...amount} label="Amount" required type="number" spaceBottom />
      <TxButton
        tx={tx.state}
        onClick={send}
        text="Burn"
        reset={tx.reset}
        wrapper
        disabled={!ready}
      />
      <TxErrorDialog tx={tx.state} reset={tx.reset} />
    </Card>
  )
}

export default BurnView
