import { TRANSACTION_TYPE } from '@waves/ts-types'

export class BCTxCreator {
  public createTransferTxData(
    recipient: string,
    amount: number | string,
    assetId: string,
    attachment?: string
  ): WavesKeeper.TTransferTxData {
    return {
      type: TRANSACTION_TYPE.TRANSFER,
      data: {
        recipient,
        amount: {
          amount,
          assetId
        },
        attachment: attachment === '' ? undefined : attachment
      }
    }
  }

  public createDataTxData(
    key: string,
    type: string,
    value: string
  ): WavesKeeper.TDataTxData {
    return {
      type: TRANSACTION_TYPE.DATA,
      data: {
        version: 2,
        data: [
          { key, type: this.parseDataType(type), value: this.parseDataValue(value, type) }
        ]
      }
    } as any
  }

  public createSetScriptTxData(script: string | null): WavesKeeper.TSetScriptTxData {
    return {
      type: TRANSACTION_TYPE.SET_SCRIPT,
      data: {
        script: script ?? null
      }
    }
  }

  public createInvokeScriptTxData(
    dApp: string,
    call: WavesKeeper.ICall
  ): WavesKeeper.TScriptInvocationTxData {
    return {
      type: TRANSACTION_TYPE.INVOKE_SCRIPT,
      data: { dApp, call, payment: [] }
    }
  }

  public createMassTransferTxData(
    total: string,
    transfers: WavesKeeper.ITransfer[]
  ): WavesKeeper.TMassTransferTxData {
    return {
      type: TRANSACTION_TYPE.MASS_TRANSFER,
      data: {
        totalAmount: {
          amount: total,
          assetId: ''
        },
        transfers
      }
    }
  }

  public createAliasTxData(alias: string): WavesKeeper.TCreateAliasTxData {
    return {
      type: TRANSACTION_TYPE.ALIAS,
      data: { alias }
    }
  }

  public createBurnTxData(assetId: string, amount: number): WavesKeeper.TBurnTxData {
    return {
      type: TRANSACTION_TYPE.BURN,
      data: { assetId, amount }
    }
  }

  public createIssueTxData(
    name: string,
    description: string,
    reissuable: string,
    quantity: string,
    decimals: string
  ): WavesKeeper.TIssueTxData {
    return {
      type: TRANSACTION_TYPE.ISSUE,
      data: {
        name,
        description,
        reissuable: reissuable === 'true',
        quantity: quantity,
        precision: Number(decimals)
      }
    }
  }

  public createLeaseTxData(address: string, amount: string): WavesKeeper.TLeaseTxData {
    return {
      type: TRANSACTION_TYPE.LEASE,
      data: {
        recipient: address,
        amount: {
          amount,
          assetId: ''
        }
      }
    }
  }

  public createLeaseCancelTxData(id: string): WavesKeeper.TLeaseCancelTxData {
    return {
      type: TRANSACTION_TYPE.CANCEL_LEASE,
      data: { leaseId: id }
    }
  }

  private parseDataValue = (value: string, type: string) => {
    if (type === 'boolean') return value === 'true'
    if (type === 'binary') return value.slice(7) === 'base64:' ? value : `base64:${value}`
    if (type === 'delete') return null
    return value
  }

  private parseDataType = (type: string) => {
    if (type === 'delete') return undefined
    return type
  }
}
