import React from 'react'
import CustomTextarea from '../../../Components/Inputs/CustomTextarea'
import { FieldProps } from '../DataInterfaces'

const validate = (value: string): string | null => {
  if (value === '') return 'Empty value'

  const num = Number(value)

  if (!Number.isSafeInteger(num)) {
    return 'Number is not a safe interger'
  }

  return null
}

const IntegerField: React.FC<FieldProps> = (props) => {
  return (
    <CustomTextarea {...props} label="Integer value" validate={validate} type="number" />
  )
}

export default IntegerField
