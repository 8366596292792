import React from 'react'
import { Divider, List, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material'
import { Route } from './Routes'
import { useLocation, useNavigate } from 'react-router-dom'

export interface MenuProps {
  routes: readonly Route[]
}

const Menu: React.FC<MenuProps> = (props) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  return (
    <Paper
      sx={{
        width: 250,
        paddingTop: '64px',
        height: '100vh',
        position: 'fixed'
      }}
    >
      <List>
        {props.routes.map((route) => {
          const Icon = route.icon

          return (
            <React.Fragment key={route.path}>
              <ListItem
                button
                disabled={route.path === pathname}
                onClick={() => navigate(route.path)}
              >
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={route.label} />
              </ListItem>
              {route.divider && <Divider />}
            </React.Fragment>
          )
        })}
      </List>
    </Paper>
  )
}

export default Menu
