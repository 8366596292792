import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert
} from '@mui/material'
import { TransactionState } from '../Providers/WvsProvider/useTransaction'
import { inspect } from 'util'

export interface TxErrorDialogProps {
  tx: TransactionState
  reset: () => void
}

const parseError = (err: any) => {
  return inspect(err)
}

const TxErrorDialog: React.FC<TxErrorDialogProps> = (props) => {
  return (
    <Dialog open={props.tx.status === 'failed'} onClose={props.reset}>
      <DialogTitle>Transaction Error</DialogTitle>
      <DialogContent>
        <Alert variant="filled" severity="error">
          {parseError(props.tx.error)}
        </Alert>
      </DialogContent>
      <DialogActions sx={{ marginRight: '5px' }}>
        <Button onClick={props.reset}>Dismiss</Button>
      </DialogActions>
    </Dialog>
  )
}

export default TxErrorDialog
