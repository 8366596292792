import React from 'react'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material'
import { inspect } from 'util'

import { useExploreNftsModals } from '../../contexts/ExploreNftsModals.context'
import { useContacts } from '../../../../Providers/ContactsProvider/ContactsProvider'
import { useCustomInput } from '../../../../Components/Inputs/CustomInput'
import { useTransaction } from '../../../../Providers/WvsProvider/useTransaction'
import { useWvs } from '../../../../Providers/WvsProvider/KeeperProvider'
import { useTransactionNotification } from '../../../../Providers/NotificationProvider/useTransactionNotification'
import { useExploreNfts } from '../../contexts/ExploreNfts.context'

import TxButton from '../../../../Components/TxButton'
import AddressField from '../../../../Components/Inputs/AddressInput'

import { sleep } from '../../../../Common/sleep'

const TransferNftModal = () => {
  const tx = useTransaction()
  const { services } = useWvs()
  const { selectedAsset, removeSelectedAsset, transferNftModalOpened, closeTransferNftModal } = useExploreNftsModals()
  const { removeAssetData } = useExploreNfts()
  const { availableContacts } = useContacts()
  const receiverAddress = useCustomInput({ required: true })

  useTransactionNotification(tx.state.status)

  const handleRemoveAsset = () => {
    if (!selectedAsset) return
    removeAssetData(selectedAsset.assetId)
  }

  const handleClose = async () => {
    closeTransferNftModal()
    await sleep(100)
    removeSelectedAsset()
    tx.reset()
  }

  const handleTransfer = async () => {
    if (!selectedAsset) return

    const params = services.txCreator.createTransferTxData(
      receiverAddress.state.value,
      1,
      selectedAsset.assetId,
      undefined
    )

    await tx.send(params).then(handleRemoveAsset).then(handleClose)
  }

  const handleTryAgain = () => {
    tx.reset()
    handleTransfer()
  }

  const error = tx.state.error
  const disableTransferBtn = !!receiverAddress.state.error || !receiverAddress.state.value

  return (
    <Dialog open={transferNftModalOpened} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Transfer token</DialogTitle>

      {!error && (
        <>
          <DialogContent>
            <DialogContentText style={{ marginBottom: '25px' }}>
              You can transfer the token to another wallet. <br />
              Enter the recipient's address and confirm the transaction.
            </DialogContentText>

            <AddressField
              {...receiverAddress}
              disabled={tx.state.status !== 'ready'}
              required
              label="Receiver address"
              contacts={availableContacts}
              spaceBottom
            />
          </DialogContent>

          <DialogActions>
            <Button color="error" onClick={handleClose}>
              Cancel
            </Button>
            <TxButton
              tx={tx.state}
              onClick={handleTransfer}
              text="Transfer"
              reset={tx.reset}
              disabled={disableTransferBtn}
            />
          </DialogActions>
        </>
      )}

      {error && (
        <>
          <DialogContent>
            <Typography style={{ marginBottom: '10px' }}>Transaction failed: </Typography>
            <Alert variant="filled" severity="error">
              {inspect(error)}
            </Alert>
          </DialogContent>

          <DialogActions>
            <Button color="error" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleTryAgain}>
              Try again
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default TransferNftModal
