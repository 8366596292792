import React from 'react'
import { parse } from '@skeynetwork/nft-meta-parser'
import { Typography } from '@mui/material'

import ImageIcon from '@mui/icons-material/Image'
import KeyIcon from '@mui/icons-material/Key'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'

import { AssetData } from '../../../../Blockchain/BCInterfaces'
import { useWvs } from '../../../../Providers/WvsProvider/KeeperProvider'

import { AssetUrl, SKEY_APP, parseAssetUrl } from '../NftTable/utils/parseAssetData'
import AssetCard from './AssetCard'

interface NftDataPreviewProps {
  asset: AssetData
}

const NftDataPreview = ({ asset }: NftDataPreviewProps) => {
  const [imagePreviewError, setImagePreviewError] = React.useState(false)
  const { publicState } = useWvs()

  const assetData = parse(asset.description)

  const assetUrl = React.useMemo<AssetUrl>(
    () => assetData && parseAssetUrl(assetData, publicState?.account?.network || 'mainnet'),
    [assetData, publicState]
  )

  const handleOpen = () => {
    if (!assetUrl) return
    return window.open(assetUrl, '_blank')
  }

  const handleErrorImagePreview = () => setImagePreviewError(true)

  if (!assetData || imagePreviewError || !assetUrl) {
    return <ImageIcon fontSize="large" />
  }

  if (
    (assetData.base.project === SKEY_APP.TIX && assetData.base.type === 'poap') ||
    assetData.base.project === SKEY_APP.GO2NFT
  ) {
    return (
      <AssetCard onClick={handleOpen}>
        <img src={assetUrl} alt="nft preview" width={40} height={40} onError={handleErrorImagePreview} />
      </AssetCard>
    )
  }

  if (assetData.base.project === SKEY_APP.TIX && assetData.base.type === 'ticket') {
    return <ConfirmationNumberIcon fontSize="large" />
  }

  if (assetData.base.project === SKEY_APP.BOX) {
    return (
      <AssetCard onClick={handleOpen}>
        <img
          src="/images/skey-box-cert-preview.png"
          alt="cert preview"
          height={38}
          // width={53}
          onError={handleErrorImagePreview}
        />
      </AssetCard>
    )
  }

  if (assetData.base.project === SKEY_APP.SKEY_NETWORK && assetData.base.type === 'device-key') {
    return <KeyIcon fontSize="large" />
  }

  return <Typography>unkown</Typography>
}

export default NftDataPreview
