import React from 'react'
import { Autocomplete, TextField, TextFieldProps } from '@mui/material'
import { verifyAddress } from '@waves/ts-lib-crypto'
import { Contact } from '../../Providers/ContactsProvider/Contact'
import { shortenHash } from '../../Common/Utils'
import { useWvs } from '../../Providers/WvsProvider/KeeperProvider'
import { CustomInputState } from './CustomInput'

const VALID_STR_LENGTH = 35
const ADDRESS_REGEX = /^[1-9A-HJ-NP-Za-km-z]{35}$/

export interface AddressInputProps {
  state: CustomInputState
  setState: (state: CustomInputState) => void
  disabled?: boolean
  required?: boolean
  label: string
  contacts?: Contact[]
  spaceBottom?: boolean
  spaceTop?: boolean
  fullWidth?: boolean
  size?: TextFieldProps['size']
}

const validate = (value: string, chainId?: string): string | null => {
  if (value.length < VALID_STR_LENGTH) {
    return `Address is too short (required ${VALID_STR_LENGTH} characters)`
  }

  if (value.length > VALID_STR_LENGTH) {
    return `Address is too long (required ${VALID_STR_LENGTH} characters)`
  }

  if (!ADDRESS_REGEX.test(value)) {
    return 'Address contains invalid characters'
  }

  if (chainId && !verifyAddress(value, { chainId })) {
    return "ChainId doesn't match current network"
  }

  if (!verifyAddress(value)) {
    return 'Address is not valid'
  }

  return null
}

const stringify = ({ address, name, chainId }: Contact) => `${address} ${chainId} ${name}`

const parse = (value: string): Contact => ({
  address: value.substring(0, VALID_STR_LENGTH),
  chainId: value.substring(VALID_STR_LENGTH + 1, VALID_STR_LENGTH + 2),
  name: value.substring(VALID_STR_LENGTH + 3, value.length)
})

const AddressInput: React.FC<AddressInputProps> = (props) => {
  const [selected, setSelected] = React.useState('')
  const [inputValue, setInputValue] = React.useState('')
  const chainId = useWvs()?.publicState?.network?.code

  React.useEffect(() => {
    setInputValue(props.state.value)
  }, [props.state.value])

  const onChange = (newValue: string | null, selected: boolean) => {
    const address = parse(newValue ?? '').address
    const value = selected ? address : newValue ?? ''

    if (selected) setSelected(address)

    setInputValue(value)

    props.setState({
      value: value,
      touched: true,
      error: validate(value, chainId),
      required: props.required ?? false
    })
  }

  return (
    <Autocomplete
      value={selected}
      onChange={(_, newValue) => onChange(newValue, true)}
      inputValue={inputValue}
      onInputChange={(_, newValue) => onChange(newValue, false)}
      freeSolo
      selectOnFocus
      handleHomeEndKeys
      options={(props.contacts ?? []).map(stringify)}
      fullWidth={props.fullWidth ?? true}
      size={props.size}
      renderOption={(p, value) => {
        const contact = parse(value)

        return (
          <li {...p}>
            {contact.name}
            &nbsp;&nbsp; [{shortenHash(contact.address)}]
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={props.label}
          error={!!props.state.error && props.state.touched}
          helperText={props.state.error}
          disabled={props.disabled}
          required={props.required}
          sx={{
            marginBottom: `${props.spaceBottom ? 20 : 0}px`,
            marginTop: `${props.spaceTop ? 20 : 0}px`
          }}
        />
      )}
    />
  )
}

export default AddressInput
