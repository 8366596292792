import { Metadata } from '@skeynetwork/nft-meta-parser'
import { SKEY_SERVICES_URLS } from '../../../../../Config/config'

export enum SKEY_APP {
  SKEY_NETWORK = 'skey-network',
  TIX = 'skey-tix',
  BOX = 'skey-box',
  GO2NFT = 'go2nft',
  UNKOWN = 'unkown'
}

export interface AssetServiceUrls {
  mainnet: string
  testnet: string
}

export interface AssetData {
  project: SKEY_APP
  assetId: string | null
}

export type AssetUrl = string | null

export const skeyBoxAssetServiceUrls: AssetServiceUrls = {
  mainnet: SKEY_SERVICES_URLS.MAINNET_SKEY_BOX_ASSET_SERVICE_URL,
  testnet: SKEY_SERVICES_URLS.TESTNET_SKEY_BOX_ASSET_SERVICE_URL
}

const skeyTixAssetServiceUrls: AssetServiceUrls = {
  mainnet: SKEY_SERVICES_URLS.MAINNET_SKEY_TIX_ASSET_SERVICE_URL,
  testnet: SKEY_SERVICES_URLS.TESTNET_SKEY_TIX_ASSET_SERVICE_URL
}

const go2NftAssetServiceUrls: AssetServiceUrls = {
  mainnet: SKEY_SERVICES_URLS.MAINNET_GO2NFT_ASSET_SERVICE_URL,
  testnet: SKEY_SERVICES_URLS.TESTNET_GO2NFT_ASSET_SERVICE_URL
}

const assetServiceUrlsMap = new Map<SKEY_APP, AssetServiceUrls>([
  [SKEY_APP.BOX, skeyBoxAssetServiceUrls],
  [SKEY_APP.TIX, skeyTixAssetServiceUrls],
  [SKEY_APP.GO2NFT, go2NftAssetServiceUrls]
])

export const getAssetUrl = (assetData: AssetData, network: string) => {
  const { project, assetId } = assetData

  const assetServiceUrls = assetServiceUrlsMap.get(project)

  if (!assetServiceUrls) return null
  const serviceUrl = network === 'testnet' ? assetServiceUrls.testnet : assetServiceUrls.mainnet

  return `${serviceUrl}/${assetId}`
}

export const parseAssetUrl = (metadata: Metadata, network: string): AssetUrl => {
  const { base: app, data } = metadata

  if (app.project === SKEY_APP.SKEY_NETWORK) {
    return null
  }

  if (app.project === SKEY_APP.GO2NFT) {
    if ('cid' in data) {
      const assetData = { project: SKEY_APP.GO2NFT, assetId: data.cid }
      const assetUrl = getAssetUrl(assetData, network)

      return assetUrl
    } else {
      return null
    }
  }

  if (app.project === SKEY_APP.TIX) {
    if ('cid' in data) {
      const assetData = { project: SKEY_APP.TIX, assetId: data.cid }
      const assetUrl = getAssetUrl(assetData, network)

      return assetUrl
    } else {
      return null
    }
  }

  if (app.project === SKEY_APP.BOX) {
    if ('uid' in data) {
      const assetData = { project: SKEY_APP.BOX, assetId: data.uid }
      const assetUrl = getAssetUrl(assetData, network)

      return assetUrl
    } else {
      return null
    }
  }

  return null
}
