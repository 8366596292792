import { Box } from '@mui/system'
import React from 'react'
import Space from '../../Components/Space'
import AddContact from './AddContact'
import ContactsList from './ContactsList'
import ImportContacts from './ImportContacts'

const ContactsView: React.FC = () => {
  return (
    <React.Fragment>
      <Box sx={{ width: '830px' }}>
        <ContactsList />
      </Box>
      <Box sx={{ width: '480px', marginLeft: '15px' }}>
        <AddContact />
        <Space />
        <ImportContacts />
      </Box>
    </React.Fragment>
  )
}

export default ContactsView
