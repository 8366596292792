export const DARK_MODE_DEFAULT_VALUE = true

// Skey services urls ************************
export const MAINNET_EXPLORER_URL = 'https://explorer.smartkeyplatform.io'
export const TESTNET_EXPLORER_URL = 'https://explorer.smartkeyplatform.io/testnet'

export const MAINNET_SKEY_BOX_ASSET_SERVICE_URL = 'https://box.skey.network/en/certificates'
export const TESTNET_SKEY_BOX_ASSET_SERVICE_URL = 'https://box.skey.network/en/certificates'

export const MAINNET_SKEY_TIX_ASSET_SERVICE_URL = 'https://scarlet-major-reindeer-396.mypinata.cloud/ipfs'
export const TESTNET_SKEY_TIX_ASSET_SERVICE_URL = 'https://g2n-dev.mypinata.cloud/ipfs'

export const MAINNET_GO2NFT_ASSET_SERVICE_URL = 'https://g2n-dev.mypinata.cloud/ipfs'
export const TESTNET_GO2NFT_ASSET_SERVICE_URL = 'https://g2n-dev.mypinata.cloud/ipfs'

export const SKEY_SERVICES_URLS = {
  MAINNET_EXPLORER_URL,
  TESTNET_EXPLORER_URL,

  MAINNET_SKEY_BOX_ASSET_SERVICE_URL,
  TESTNET_SKEY_BOX_ASSET_SERVICE_URL,

  MAINNET_SKEY_TIX_ASSET_SERVICE_URL,
  TESTNET_SKEY_TIX_ASSET_SERVICE_URL,

  MAINNET_GO2NFT_ASSET_SERVICE_URL,
  TESTNET_GO2NFT_ASSET_SERVICE_URL
}

// Skey services urls ************************
