import React from 'react'
import { OutlinedTextFieldProps, TextField } from '@mui/material'
import { CustomInputState } from './CustomInput'

export interface CustomTextareaProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  state: CustomInputState
  setState: (state: CustomInputState) => void
  validate?: (value: string) => string | null
  spaceBottom?: boolean
  spaceTop?: boolean
}

const validateDefault = (current: string, props: CustomTextareaProps): string | null => {
  if (props.required && props.state.touched && !current) return 'Field is required'

  if (props.validate) {
    return props.validate(current)
  } else {
    return null
  }
}

const CustomTextarea: React.FC<CustomTextareaProps> = (props) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setState({
      value: e.target.value,
      error: validateDefault(e.target.value, props),
      touched: true,
      required: props.required ?? false
    })
  }

  const { state, setState, validate, spaceBottom, spaceTop, ...extended } = props

  return (
    <TextField
      {...extended}
      value={props.state.value}
      onChange={onChange}
      variant="outlined"
      error={!!props.state.error}
      helperText={props.state.error}
      fullWidth
      sx={{
        marginBottom: `${props.spaceBottom ? 20 : 0}px`,
        marginTop: `${props.spaceTop ? 20 : 0}px`
      }}
    />
  )
}

export default CustomTextarea
