import { TRANSACTION_TYPE } from '@waves/ts-types'
import { BCReadService } from './BCReadService'

export class BCFeeCalculator {
  private readonly FEE_UNIT = 10 ** 5
  private readService: BCReadService

  public address?: string
  public scriptCache: boolean | null = null

  constructor(readService: BCReadService) {
    this.readService = readService
  }

  public update(address?: string) {
    if (this.address !== address) {
      this.scriptCache = null
      this.address = address
    }
  }

  // Call when user modifies account script
  public setScriptCache(script?: boolean) {
    this.scriptCache = script ?? null
  }

  // Append calculated fee to tx
  public async appendFee<T extends WavesKeeper.TSignTransactionData>(
    txData: T
  ): Promise<T> {
    const accountScript = await this.checkAccountScript()

    const standardFee = this.getFee(txData)
    const accountScriptFee = accountScript ? 4 * this.FEE_UNIT : 0
    const fee = standardFee + accountScriptFee

    return {
      ...txData,
      data: { ...txData.data, fee: { assetId: '', amount: fee } }
    }
  }

  private getFee(txData: WavesKeeper.TSignTransactionData): number {
    switch (txData.type) {
      case TRANSACTION_TYPE.TRANSFER:
        return this.calculateStandardFee(txData)
      case TRANSACTION_TYPE.SET_SCRIPT:
        return this.calculateSetScriptFee(txData)
      case TRANSACTION_TYPE.MASS_TRANSFER:
        return this.calculateMassTransferFee(txData)
      case TRANSACTION_TYPE.DATA:
        return this.calculateDataFee(txData)
      case TRANSACTION_TYPE.INVOKE_SCRIPT:
        return this.calculateInvokeScriptFee(txData)
      case TRANSACTION_TYPE.ALIAS:
        return this.calculateStandardFee(txData)
      case TRANSACTION_TYPE.BURN:
        return this.calculateStandardFee(txData)
      case TRANSACTION_TYPE.ISSUE:
        return this.calculateIssueFee(txData)
      case TRANSACTION_TYPE.LEASE:
        return this.calculateStandardFee(txData)
      case TRANSACTION_TYPE.CANCEL_LEASE:
        return this.calculateStandardFee(txData)
    }

    throw new Error('Tx fee calculation not implemented')
  }

  private calculateStandardFee(txData: WavesKeeper.TSignTransactionData) {
    return this.FEE_UNIT
  }

  private calculateSetScriptFee(txData: WavesKeeper.TSignTransactionData) {
    return 10 * this.FEE_UNIT
  }

  // TODO handle token creation while executing script
  private calculateInvokeScriptFee(txData: WavesKeeper.TSignTransactionData) {
    return 5 * this.FEE_UNIT
  }

  private calculateIssueFee(txData: WavesKeeper.TSignTransactionData) {
    const { data } = txData as WavesKeeper.TIssueTxData

    // is nft
    if (
      !data.reissuable &&
      (data.quantity === '1' || data.quantity === 1) &&
      data.precision === 0
    )
      return this.FEE_UNIT

    return 1000 * this.FEE_UNIT
  }

  private calculateMassTransferFee(txData: WavesKeeper.TSignTransactionData) {
    const tx = txData as WavesKeeper.TMassTransferTxData

    const result = this.FEE_UNIT + tx.data.transfers.length * (this.FEE_UNIT / 2)

    // Should be rounded to 3 decimals
    if (tx.data.transfers.length % 2 === 0) {
      return result
    }

    return result + this.FEE_UNIT / 2
  }

  // TODO handle fee based on tx data size
  private calculateDataFee(txData: WavesKeeper.TSignTransactionData) {
    return this.FEE_UNIT
  }

  private async checkAccountScript(): Promise<boolean> {
    if (typeof this.scriptCache === 'boolean') return this.scriptCache

    try {
      const script = await this.readService.getScript(this.address!)
      this.scriptCache = !!script
      return !!script
    } catch (e) {
      console.error(e)
      console.warn('Cannot fetch script info, assuming more expensive tx fee')
      this.scriptCache = true
      return true
    }
  }
}
