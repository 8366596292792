import React from 'react'
import { inspect } from 'util'
import { BCReadService } from '../../Blockchain/BCReadService'
import { useWvs } from './KeeperProvider'

export interface UseNodeDataOptions<T> {
  initialValue: T
  exec: (service: BCReadService, address: string, chainId: string) => Promise<T>
}

export interface UseNodeDataState<T> {
  data: T
  loading: boolean
  error: string | null
  reset: () => void
  forceFetch: () => void
}

export const useNodeData = <T,>(options: UseNodeDataOptions<T>): UseNodeDataState<T> => {
  const [data, setData] = React.useState<T>(options.initialValue)
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState<string | null>(null)
  const { services, publicState } = useWvs()

  const reset = () => {
    setLoading(true)
    setError(null)
    setData(options.initialValue)
  }

  const canExec = () => {
    return !!publicState?.account?.address && !!publicState?.network?.server && !!publicState?.network?.code
  }

  const exec = async () => {
    const address = publicState?.account?.address ?? ''
    const chainId = publicState?.network?.code ?? 'R'

    reset()

    options
      .exec(services.readService, address, chainId)
      .then(setData)
      .catch((e) => {
        console.error(e)
        setError(inspect(e))
      })
      .finally(() => setLoading(false))
  }

  React.useEffect(() => {
    if (!canExec()) return

    exec()

    // eslint-disable-next-line
  }, [publicState])

  return { data, loading, error, reset, forceFetch: exec }
}
