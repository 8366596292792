import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FunctionInput, { FunctionInputProps } from './FunctionInput'
import Space from '../../Components/Space'
import { useTransaction } from '../../Providers/WvsProvider/useTransaction'
import { useWvs } from '../../Providers/WvsProvider/KeeperProvider'
import TxButton from '../../Components/TxButton'
import { useTransactionNotification } from '../../Providers/NotificationProvider/useTransactionNotification'
import type { FType } from './FunctionsInterfaces'
import TxErrorDialog from '../../Components/TxErrorDialog'

export interface FunctionBoxProps {
  dapp: string
  name: string
  fields: {
    name: string
    type: FType
  }[]
}

export const mapToKeeperTypes = (t: string) => {
  if (t === 'String') return 'string'
  if (t === 'Int') return 'integer'
  if (t === 'Boolean') return 'boolean'

  throw new Error('type not implemented')
}

export const initialInputsProps = (schema: FunctionBoxProps): FunctionInputProps[] => {
  return schema.fields.map((field, index) => ({
    index,
    value: field.type === 'Boolean' ? 'true' : '',
    setValue: () => {},
    type: field.type,
    label: field.name
  }))
}

const FunctionBox: React.FC<FunctionBoxProps> = (props) => {
  const tx = useTransaction()
  useTransactionNotification(tx.state.status)
  const { services } = useWvs()
  const [fields, setFields] = React.useState(initialInputsProps(props))

  const send = async () => {
    const call: WavesKeeper.ICall = {
      function: props.name,
      args: fields.map((field) => ({
        type: mapToKeeperTypes(field.type) as any,
        value: field.type === 'Boolean' ? field.value === 'true' : field.value
      }))
    }

    const params = services.txCreator.createInvokeScriptTxData(props.dapp, call)
    await tx.send(params)
  }

  const setValue = (value: string, index: number) => {
    setFields(
      fields.map((field) => {
        if (field.index !== index) return field

        return { ...field, value }
      })
    )
  }

  return (
    <Accordion sx={{ width: '100%' }} disableGutters>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography fontSize="1.25rem">{props.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Space />
        {fields.map((field) => (
          <FunctionInput
            key={field.index}
            {...field}
            setValue={(value) => setValue(value, field.index)}
          />
        ))}
        <TxButton
          tx={tx.state}
          onClick={send}
          text="Invoke script"
          reset={tx.reset}
          wrapper
        />
        <TxErrorDialog tx={tx.state} reset={tx.reset} />
      </AccordionDetails>
    </Accordion>
  )
}

export default FunctionBox
