import React from 'react'
import { ToggleButtonGroup, ToggleButton, InputLabel, Box } from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

export type TxOrigin = 'incoming' | 'outcoming' | 'both'

export interface TxOriginSelectProps {
  state: TxOrigin
  setState: (state: TxOrigin) => void
}

export const initialTxOriginSelectState = (): TxOrigin => 'both'

const TxOriginSelect: React.FC<TxOriginSelectProps> = ({ state, setState }) => {
  return (
    <Box>
      <InputLabel sx={{ fontSize: '13px', marginBottom: '2px' }}>
        Transaction origin
      </InputLabel>
      <ToggleButtonGroup
        value={state}
        exclusive
        onChange={(_, newState) => setState(newState)}
        size="small"
      >
        <ToggleButton value="both">
          Both
          <ArrowUpwardIcon fontSize="small" color="success" sx={{ marginLeft: '10px' }} />
          <ArrowDownwardIcon fontSize="small" color="info" />
        </ToggleButton>
        <ToggleButton value="outcoming">
          Outcoming
          <ArrowUpwardIcon fontSize="small" color="success" sx={{ marginLeft: '10px' }} />
        </ToggleButton>
        <ToggleButton value="incoming">
          Incoming
          <ArrowDownwardIcon fontSize="small" color="info" sx={{ marginLeft: '10px' }} />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

export default TxOriginSelect
