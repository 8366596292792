import React from 'react'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material'
import { inspect } from 'util'

import { useExploreNftsModals } from '../../contexts/ExploreNftsModals.context'
import { useTransaction } from '../../../../Providers/WvsProvider/useTransaction'
import { useTransactionNotification } from '../../../../Providers/NotificationProvider/useTransactionNotification'
import { useWvs } from '../../../../Providers/WvsProvider/KeeperProvider'
import { useExploreNfts } from '../../contexts/ExploreNfts.context'

import TxButton from '../../../../Components/TxButton'
import { sleep } from '../../../../Common/sleep'

const BurnNftModal = () => {
  const tx = useTransaction()
  const { services } = useWvs()
  useTransactionNotification(tx.state.status)

  const { selectedAsset, removeSelectedAsset, burnNftModalOpened, closeBurnNftModal } = useExploreNftsModals()
  const { removeAssetData } = useExploreNfts()

  const handleRemoveAsset = () => {
    if (!selectedAsset) return
    removeAssetData(selectedAsset.assetId)
  }

  const handleClose = async () => {
    closeBurnNftModal()
    await sleep(100)
    removeSelectedAsset()
    tx.reset()
  }

  const handleTryAgain = () => {
    tx.reset()
    handleBurn()
  }

  const handleBurn = async () => {
    if (!selectedAsset) return
    const params = services.txCreator.createBurnTxData(selectedAsset.assetId, 1)

    await tx.send(params).then(handleRemoveAsset).then(handleClose)
  }

  const error = tx.state.error

  return (
    <Dialog open={burnNftModalOpened} onClose={handleClose}>
      <DialogTitle>Burn token</DialogTitle>

      {error ? (
        <>
          <DialogContent>
            <Typography style={{ marginBottom: '10px' }}>Transaction failed: </Typography>
            <Alert variant="filled" severity="error">
              {inspect(error)}
            </Alert>
          </DialogContent>

          <DialogActions>
            <Button color="error" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleTryAgain}>
              Try again
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to burn the token <br />
              <span style={{ color: '#f44336' }}>${selectedAsset?.assetId}</span> ? <br />
              If you do this, you won't be able to get it back
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button color="error" onClick={handleClose}>
              Cancel
            </Button>
            <TxButton tx={tx.state} onClick={handleBurn} text="Burn" reset={tx.reset} />
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default BurnNftModal
