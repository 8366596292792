import React from 'react'
import { Avatar, Box, useTheme } from '@mui/material'
import * as IdentityImg from 'identity-img'

import { useWvs } from '../Providers/WvsProvider/KeeperProvider'
import { useNodeData } from '../Providers/WvsProvider/useNodeData'

import { shortenHash } from '../Common/Utils'
import { copyToClipboard } from '../Common/copyToClipboard'

import InfoChip from './InfoChip'

const getChainIdName = (chainId?: string) => {
  if (!chainId) return 'Unknown'

  if (chainId === 'M') return 'Mainnet [M]'
  if (chainId === 'T') return 'Testnet [T]'
  if (chainId === 'A') return 'Testnet [A]'
  if (chainId === 'S') return 'Stagenet [S]'
  if (chainId === 'D') return 'Devnet [D]'

  return `Custom [${chainId}]`
}

const BalanceInfo: React.FC = () => {
  const { publicState, status } = useWvs()
  const { palette } = useTheme()

  const assetUrl = useNodeData({
    initialValue: null,
    exec: (service, address) => service.getAssetUrl(address)
  })

  const num = React.useMemo(() => {
    const balance = publicState?.account?.balance.available
    if (!balance) return 'N/A'

    return (Number(balance) / 10 ** 8).toFixed(4)
  }, [publicState?.account])

  const address = React.useMemo(() => {
    const value = publicState?.account?.address
    if (!value) return 'N/A'

    return shortenHash(value)
  }, [publicState?.account])

  const avatarUrl = React.useMemo(() => {
    const address = publicState?.account?.address
    if (!address) return null

    return IdentityImg.create(address, { size: 120, rows: 8, cells: 8 })
  }, [publicState])

  const handleCopyAddress = () => copyToClipboard(publicState?.account?.address || '')

  if (status !== 'connected') return null

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <InfoChip label="Name" value={publicState?.account?.name} color="secondary" />
      <InfoChip label="Address" value={address} color="secondary" onClick={handleCopyAddress} />
      <InfoChip label="Balance" value={num} color={palette.mode === 'light' ? 'secondary' : 'primary'} />
      <InfoChip
        label="Chain"
        value={getChainIdName(publicState?.network?.code)}
        color={palette.mode === 'light' ? 'secondary' : 'primary'}
      />
      {!!assetUrl.data && (
        <Avatar src={assetUrl.data} alt="asset-url" sx={{ width: '40px', height: '40px', marginRight: '10px' }} />
      )}
      {!!avatarUrl && <Avatar src={avatarUrl} alt="address-avatar" sx={{ width: '40px', height: '40px' }} />}
    </Box>
  )
}

export default BalanceInfo
