import React from 'react'
import { Paper, PaperProps, Typography } from '@mui/material'
import Space from './Space'

export interface CardProps {
  title: string
  maxWidth?: number
  sx?: PaperProps['sx']
}

const Card: React.FC<CardProps> = (props) => {
  return (
    <Paper
      elevation={2}
      sx={{
        padding: '20px 15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: '60px',
        height: 'min-content',
        maxWidth: `${props.maxWidth ?? 600}px`,
        width: '100%',
        ...(props.sx ?? {})
      }}
    >
      <Typography variant="h6" style={{ width: '100%' }}>
        {props.title}
      </Typography>
      <Space height={30} />
      {props.children}
    </Paper>
  )
}

export default Card
