import { Typography } from '@mui/material'
import React from 'react'
import Card from '../../Components/Card'

const NotFoundView: React.FC = () => {
  return (
    <Card title="404" maxWidth={500} sx={{ alignItems: 'flex-start' }}>
      <Typography color={(theme) => theme.palette.grey[500]}>Page not found</Typography>
    </Card>
  )
}

export default NotFoundView
