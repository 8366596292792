import { CellProps, Column } from 'react-table'
import { Transaction } from '../../../Blockchain/BCInterfaces'
import HashText from '../../../Components/HashText'
import {
  TransactionActions,
  TransactionDate,
  TransactionId,
  TransactionType
} from './TransactionCellComponents'
import { dateFilter, txOriginFilter, typeFilter } from './TableFilters'

export const columnsSchema: Column<Transaction>[] = [
  {
    Header: 'Id',
    accessor: 'id',
    Cell: (props) => <TransactionId {...props} />
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: (props) => <TransactionType {...props} />,
    filter: typeFilter
  },
  {
    Header: 'Sender',
    accessor: 'sender',
    filter: txOriginFilter,
    Cell: (props) => (
      <HashText text={props.row.original.sender} explorerPath="/address" tooltip />
    )
  },
  {
    Header: 'Date',
    accessor: 'timestamp',
    filter: dateFilter,
    Cell: (props) => <TransactionDate {...props} />
  },
  {
    Header: 'Actions',
    id: 'actions',
    Cell: (props: CellProps<Transaction, any>) => <TransactionActions {...props} />
  }
]
