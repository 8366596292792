import React from 'react'
import { Alert, AlertColor, Snackbar } from '@mui/material'

export interface NotificationData {
  text: string
  severity?: AlertColor
  open: boolean
}

export interface NotificationState {
  notify: (text: string, severity?: AlertColor) => void
}

export const NoificationContext = React.createContext<NotificationState>(null as any)

export const NotificationProvider: React.FC = (props) => {
  const [state, setState] = React.useState<NotificationData>({ text: '', open: false })

  const notify: NotificationState['notify'] = (text, severity) => {
    setState({
      text,
      severity,
      open: true
    })
  }

  return (
    <NoificationContext.Provider value={{ notify }}>
      {props.children}
      <Snackbar
        open={state.open}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => setState({ ...state, open: false })}
        autoHideDuration={1500}
      >
        <Alert
          severity={state.severity}
          variant="filled"
          onClose={() => setState({ ...state, open: false })}
        >
          {state.text}
        </Alert>
      </Snackbar>
    </NoificationContext.Provider>
  )
}

export const useNotifications = () => React.useContext(NoificationContext)
