import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

export type DelimiterType = '\t' | ',' | ';' | ' ' | string

export interface DelimiterSelectProps {
  id: string
  value: DelimiterType
  onChange: (value: DelimiterType) => any
  disabled?: boolean
}

const DelimiterSelect: React.FC<DelimiterSelectProps> = (props) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={`${props.id}-label`}>Delimiter</InputLabel>
      <Select
        labelId={`${props.id}-label`}
        id={props.id}
        value={props.value}
        label="Delimiter"
        onChange={(e) => props.onChange(e.target.value)}
        disabled={props.disabled}
      >
        <MenuItem value=",">Comma</MenuItem>
        <MenuItem value=";">Semicolon</MenuItem>
        <MenuItem value="\t">Tab</MenuItem>
        <MenuItem value=" ">Space</MenuItem>
      </Select>
    </FormControl>
  )
}

export default DelimiterSelect
