import { CircularProgress } from '@mui/material'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import Card from '../../Components/Card'
import { useSettings } from '../../Providers/SettingsProvider/SettingsProvider'
import { useWvs } from '../../Providers/WvsProvider/KeeperProvider'
import './markdown.css'

const matchFilename = (chainId: string) => {
  if (chainId === 'A' || chainId === 'T') return 'testnet'
  if (chainId === 'M') return 'mainnet'

  return 'default'
}

const fetchMarkdown = async (filename: string) => {
  const url = `${window.location.origin}/markdown/${filename}.md`

  const res = await fetch(url).catch(() => null)
  if (!res) return `Failed to fetch from ${url}`

  const text = await res.text().catch(() => null)
  if (!text) return 'Failed to parse response'

  return text
}

const InfoView: React.FC = () => {
  const [content, setContent] = React.useState('')
  const [filename, setFilename] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const { darkMode } = useSettings()
  const { publicState } = useWvs()

  const fetchContent = async (chainId: string) => {
    setLoading(true)
    setContent('')

    const filename = matchFilename(chainId)
    const content = await fetchMarkdown(filename)

    setFilename(filename)
    setContent(content)
    setLoading(false)
  }

  React.useEffect(() => {
    if (!publicState?.network?.code) return

    fetchContent(publicState?.network?.code)

    // eslint-disable-next-line
  }, [publicState?.network?.code])

  return (
    <Card
      title={`Blockchain info [${filename}.md]`}
      sx={{ paddingLeft: '20px' }}
      maxWidth={1200}
    >
      {loading && <CircularProgress size={40} sx={{ margin: '20px 0' }} />}
      <ReactMarkdown
        children={content}
        className={`md md-${darkMode ? 'dark' : 'light'}`}
      />
    </Card>
  )
}

export default InfoView
