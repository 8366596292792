import { Box } from '@mui/material'
import React from 'react'
import Space from '../../Components/Space'
import { useNodeData } from '../../Providers/WvsProvider/useNodeData'
import LeaseCancelForm from './LeaseCancelForm'
import LeaseForm from './LeaseForm'
import LeaseList from './LeaseList'

const LeaseView: React.FC = () => {
  const leaseRequest = useNodeData({
    initialValue: [],
    exec: (service, address) => service.activeLeases(address)
  })

  return (
    <React.Fragment>
      <Box sx={{ display: 'inline-block', width: '530px' }}>
        <LeaseForm forceUpdate={leaseRequest.forceFetch} />
        <Space />
        <LeaseCancelForm forceUpdate={leaseRequest.forceFetch} />
      </Box>
      <Box sx={{ display: 'inline-block', width: '630px', marginLeft: '15px' }}>
        <LeaseList
          leases={leaseRequest.data}
          loading={leaseRequest.loading}
          error={leaseRequest.error}
        />
      </Box>
    </React.Fragment>
  )
}

export default LeaseView
