import React from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material'
import { CustomInputState } from './CustomInput'

export interface CustomSelectOption {
  value: string
  label: string | JSX.Element
}

export interface CustomSelectProps {
  state: CustomInputState
  setState: (state: CustomInputState) => void
  label: string
  disabled?: boolean
  options: CustomSelectOption[]
  spaceBottom?: boolean
  spaceTop?: boolean
}

const CustomSelect: React.FC<CustomSelectProps> = (props) => {
  const id = Math.random().toString(36).substring(2)

  const onChange = (e: SelectChangeEvent<string>) => {
    props.setState({ error: null, value: e.target.value, touched: true, required: true })
  }

  const value = props.options.find((option) => option.value === props.state.value)
    ? props.state.value
    : ''

  return (
    <FormControl fullWidth>
      <InputLabel id={`${id}-label`}>{props.label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        value={value}
        label={props.label}
        onChange={onChange}
        disabled={props.disabled}
        sx={{
          marginBottom: `${props.spaceBottom ? 20 : 0}px`,
          marginTop: `${props.spaceTop ? 20 : 0}px`
        }}
      >
        {props.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CustomSelect
