import React from 'react'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

import Space from '../../Components/Space'
import TxAddressInput, { initialTxAddressInputState } from '../../Components/TxInputs/TxAddressInput'
import TimestampRangeSelect, {
  initialTimestampRangeSelectState,
  TimestampRange
} from '../../Components/TxInputs/TimestampRangeSelect'
import TxOriginSelect, { initialTxOriginSelectState, TxOrigin } from '../../Components/TxInputs/TxOriginSelect'
import TxTypeSelect, { initialTxTypeSelectState } from '../../Components/TxInputs/TxTypeSelect'

export interface FilterOptions {
  address: string | null
  types: number[]
  origin: TxOrigin
  timestampRange: TimestampRange
}

export interface FilterControllerProps {
  options: FilterOptions
  setOptions: React.Dispatch<React.SetStateAction<FilterOptions>>
}

export const initialFilterOptions = (): FilterOptions => ({
  address: initialTxAddressInputState(),
  types: initialTxTypeSelectState(),
  origin: initialTxOriginSelectState(),
  timestampRange: initialTimestampRangeSelectState()
})

const FilterController: React.FC<FilterControllerProps> = (props) => {
  const { options, setOptions } = props

  return (
    <Accordion sx={{ width: '100%', marginBottom: '0 !important' }}>
      <AccordionSummary expandIcon={<ExpandMore />}>Filter options</AccordionSummary>
      <AccordionDetails>
        <TxAddressInput state={options.address} setState={(address) => setOptions({ ...options, address })} />
        <Space height={25} />
        <TxTypeSelect state={options.types} setState={(types) => setOptions({ ...options, types })} />
        <Space height={15} />
        <TxOriginSelect state={options.origin} setState={(origin) => setOptions({ ...options, origin })} />
        <Space height={25} />
        <TimestampRangeSelect
          state={options.timestampRange}
          setState={(timestampRange) => setOptions({ ...options, timestampRange })}
        />
        <Space height={10} />
      </AccordionDetails>
    </Accordion>
  )
}

export default FilterController
