import React from 'react'
import { TableState, useFilters, usePagination, useSortBy, useTable } from 'react-table'

import { AssetData } from '../../../../Blockchain/BCInterfaces'
import { NftFilterOptions } from '../NftFilterController/NftFilterController'
import { useWvs } from '../../../../Providers/WvsProvider/KeeperProvider'

import TableRenderer from '../../../../Components/TableRenderer'

import { columnsSchema } from './NftTableColumns'

export interface NftTableProps {
  assets: AssetData[]
  options: NftFilterOptions
}

export const initialTableState = (): Partial<TableState<AssetData>> => ({
  sortBy: [{ id: 'assetId', desc: false }],
  pageSize: 25
})

const NftTable = ({ assets, options }: NftTableProps) => {
  const { publicState } = useWvs()

  // eslint-disable-next-line
  const columns = React.useMemo(() => columnsSchema(options.address || ''), [options.address])
  // eslint-disable-next-line
  const data = React.useMemo(() => assets, [assets])
  // eslint-disable-next-line
  const initialState = React.useMemo(() => initialTableState(), [])

  const instance = useTable({ columns, data, initialState }, useFilters, useSortBy, usePagination)

  React.useEffect(() => {
    instance.setAllFilters([{ id: 'issueTimestamp', value: options.timestampRange }])

    // eslint-disable-next-line
  }, [options, publicState])

  return <TableRenderer instance={instance} component="Paper" />
}

export default NftTable
