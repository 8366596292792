import React from 'react'
import { CustomInputState } from './CustomInput'
import CustomTextarea from './CustomTextarea'

export interface AmountInputProps {
  state: CustomInputState
  setState: (state: CustomInputState) => void
  disabled?: boolean
  denomination: string
  required?: boolean
  spaceBottom?: boolean
  spaceTop?: boolean
  label?: string
}

const validate = (value: string, denomination: string): string | null => {
  const MAX_COIN_VALUE = 1_000_000_000_000
  const num = Number(value)

  if (value === '') {
    return 'Amount is not a valid number'
  }

  if (!Number.isFinite(num)) {
    return 'Amount is not a valid number'
  }

  if (num < 0) {
    return 'Amount is negative'
  }

  if (num === 0) {
    return 'Amount is 0'
  }

  if (num > MAX_COIN_VALUE && denomination === 'coin') {
    return 'Amount is too high'
  }

  if (!Number.isSafeInteger(num) && denomination === 'unit') {
    return 'Number is not a safe integer'
  }

  if (denomination === 'coin' && value.split('.')[1]?.length > 8) {
    return 'Maximal precission is 8'
  }

  return null
}

const AmountInput: React.FC<AmountInputProps> = (props) => {
  React.useEffect(() => {
    if (!props.state.touched) return
    props.setState({ ...props.state, error: validate(props.state.value, denomination) })
    // eslint-disable-next-line
  }, [props.denomination])

  const { denomination, ...standardProps } = props

  return (
    <CustomTextarea
      {...standardProps}
      type="number"
      label={props.label ?? 'Amount'}
      validate={(val) => validate(val, denomination)}
      spaceBottom={props.spaceBottom}
      spaceTop={props.spaceTop}
    />
  )
}

export default AmountInput
