import React from 'react'
import CustomSelect from '../../../Components/Inputs/CustomSelect'
import { FieldProps } from '../DataInterfaces'

const BooleanSelect: React.FC<FieldProps> = (props) => {
  return (
    <CustomSelect
      {...props}
      label="Boolean value"
      options={[
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' }
      ]}
    />
  )
}

export default BooleanSelect
