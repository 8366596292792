import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import Card from '../../Components/Card'
import { useContacts } from '../../Providers/ContactsProvider/ContactsProvider'
import { useSettings } from '../../Providers/SettingsProvider/SettingsProvider'
import { useWvs } from '../../Providers/WvsProvider/KeeperProvider'
import ContactListItem from './ContactListItem'

export interface ContactsListProps {}

const ContactsList: React.FC<ContactsListProps> = (props) => {
  const { contacts, deleteContact } = useContacts()
  const { publicState } = useWvs()
  const { servicesUrls } = useSettings()

  const sorted = React.useMemo(() => {
    const target = publicState?.network?.code
    if (!target) return contacts

    return [...contacts.filter((c) => c.chainId === target), ...contacts.filter((c) => c.chainId !== target)]
  }, [contacts, publicState?.network?.code])

  if (!contacts.length)
    return (
      <Card title="Contacts" maxWidth={800}>
        <Typography variant="body2">No contacts.</Typography>
      </Card>
    )

  return (
    <Card title="Contacts" maxWidth={800} sx={{ marginBottom: '20px' }}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Address</TableCell>
              <TableCell align="right">Chain Id</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sorted.map((contact) => (
              <ContactListItem
                contact={contact}
                key={contact.name}
                deleteHandler={() => deleteContact(contact)}
                explorerUrl={servicesUrls.explorerUrl}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default ContactsList
