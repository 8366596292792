import React from 'react'
import Card from '../../Components/Card'
import DataEntry from './DataEntry'

const DataView: React.FC = () => {
  return (
    <Card title="Data" maxWidth={500}>
      <DataEntry />
    </Card>
  )
}

export default DataView
