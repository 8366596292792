import React, { CSSProperties } from 'react'
import { Tooltip, Typography } from '@mui/material'

interface EllipsisTextProps {
  text: string
  maxWidth?: number
  float?: CSSProperties['float']
  style?: React.CSSProperties
}

const EllipsisText = ({ text, maxWidth = 100, float = 'left', style }: EllipsisTextProps) => {
  return (
    <Tooltip title={text}>
      <Typography
        noWrap
        sx={{
          textOverflow: 'ellipsis',
          maxWidth,
          float,
          ...(style ?? {})
        }}
      >
        {text}
      </Typography>
    </Tooltip>
  )
}

export default EllipsisText
