import { Box, Button } from '@mui/material'
import Card from '../../Components/Card'
import * as Crypto from '@waves/ts-lib-crypto'
import React from 'react'
import { useWvs } from '../../Providers/WvsProvider/KeeperProvider'
import RefreshIcon from '@mui/icons-material/Refresh'
import Space from '../../Components/Space'
import HashText from '../../Components/HashText'

export interface Account {
  address: string
  seed: string
}

const shortSeed = (seed: string, words: number) => {
  const arr = seed.trim().split(' ')
  return arr.slice(0, words).join(' ') + '...'
}

const createAccount = (chainId: string): Account => {
  const seed = Crypto.randomSeed()
  const address = Crypto.address(seed, chainId)

  return { address, seed }
}

const createAccounts = (chainId: string, n: number): Account[] =>
  Array(n)
    .fill(null)
    .map(() => createAccount(chainId))

const AddressGeneratorView: React.FC = () => {
  const [accounts, setAccounts] = React.useState<Account[]>([])
  const { publicState } = useWvs()

  const refresh = React.useCallback(() => {
    if (!publicState?.network?.code) return

    setAccounts(createAccounts(publicState.network?.code, 10))
  }, [publicState?.network?.code])

  React.useEffect(() => refresh(), [refresh])

  return (
    <Card title="Address generator" maxWidth={720}>
      <Box>
        {accounts.map((acc) => (
          <div
            key={acc.address}
            style={{
              marginBottom: 10,
              fontFamily: 'monospace',
              fontSize: 16
            }}
          >
            <HashText
              text={acc.address}
              substringLength={35}
              tooltip
              copyOnClick
              tooltipPlacement="right"
            />
            <HashText
              text={acc.seed}
              tooltip
              copyOnClick
              displayValue={shortSeed(acc.seed, 4)}
              tooltipPlacement="right"
              style={{ marginLeft: '30px' }}
            />
          </div>
        ))}
      </Box>
      <Space />
      <Button variant="contained" endIcon={<RefreshIcon />} onClick={refresh}>
        Refresh
      </Button>
      <Space />
    </Card>
  )
}

export default AddressGeneratorView
