import { TextField } from '@mui/material'
import React from 'react'
import { ContactsStorageState } from '../../Providers/ContactsProvider/useContactsStorage'

export interface NameFieldState {
  value: string
  error: string | null
  touched: boolean
}

export interface NameFieldProps {
  state: NameFieldState
  setState: (state: NameFieldState) => void
  exists: ContactsStorageState['exists']
}

export const nameFieldInitialState = (): NameFieldState => ({
  value: '',
  error: null,
  touched: false
})

const validate = (
  value: string,
  exists: ContactsStorageState['exists']
): string | null => {
  if (!value.length) return 'Field is empty'

  if (value.includes(',')) return 'Cannot use commas'

  if (exists({ name: value, address: '', chainId: '' })) return 'Contact already exists'

  return null
}

const NameField: React.FC<NameFieldProps> = (props) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setState({
      value: e.target.value,
      error: validate(e.target.value, props.exists),
      touched: true
    })
  }

  return (
    <TextField
      label="Name"
      fullWidth
      value={props.state.value}
      onChange={onChange}
      required
      error={!!props.state.error}
      helperText={props.state.error}
    />
  )
}

export default NameField
