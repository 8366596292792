import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import NotFoundView from '../Views/NotFound/NotFoundView'
import Redirect from './Redirect'
import type { Route as IRoute } from './Routes'

export interface NavigationProps {
  defaultPath: string
  routes: readonly IRoute[]
  layout: React.FC<{}>
}

const Navigation: React.FC<NavigationProps> = (props) => {
  const Layout = props.layout

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {props.routes.map((route) => {
            const Element = route.component

            return <Route path={route.path} key={route.path} element={<Element />} />
          })}
          <Route path="*" element={<NotFoundView />} />
        </Routes>
        <Redirect from="/" to={props.defaultPath} />
      </Layout>
    </BrowserRouter>
  )
}

export default Navigation
