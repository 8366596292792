import React from 'react'
import { Contact } from './Contact'
import { StorageService } from './StorageService'
import { useNotifications } from '../NotificationProvider/NotificationProvider'
import { useWvs } from '../WvsProvider/KeeperProvider'

export interface ContactsStorageState {
  contacts: Contact[]
  availableContacts: Contact[]
  addContact: (contact: Contact) => void
  addContacts: (contacts: Contact[]) => void
  deleteContact: (contact: Contact) => void
  exists: (contact: Contact) => boolean
}

export const useContactsStorage = (): ContactsStorageState => {
  const service = React.useMemo(() => new StorageService(), [])
  const [contacts, setContacts] = React.useState<Contact[]>(service.getAll())
  const { notify } = useNotifications()
  const { publicState } = useWvs()

  const addContact = (contact: Contact) => {
    service.insertOne(contact)
    setContacts([...contacts, contact])
    notify('Contact added', 'info')
  }

  const addContacts = (newContacts: Contact[]) => {
    service.insertMany(newContacts)
    setContacts([...contacts, ...newContacts])
    notify(`${newContacts.length} contacts added`, 'info')
  }

  const deleteContact = (contact: Contact) => {
    service.deleteOne(contact)
    setContacts(contacts.filter((item) => item.name !== contact.name))
    notify('Contact deleted', 'info')
  }

  const exists = (contact: Contact) => {
    return !!contacts.find((item) => item.name === contact.name)
  }

  const availableContacts = contacts.filter(
    (contact) => contact.chainId === publicState?.network?.code
  )

  return { contacts, availableContacts, addContact, addContacts, deleteContact, exists }
}
