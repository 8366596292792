import React from 'react'
import CustomTextarea from '../../../Components/Inputs/CustomTextarea'
import { FieldProps } from '../DataInterfaces'

const validate = (_value: string): string | null => {
  const value = _value.replace('base64:', '')

  const regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/g

  if (!regex.test(value) && value !== '') {
    return 'Invalid base64 input'
  }

  return null
}

const BinaryField: React.FC<FieldProps> = (props) => {
  return (
    <CustomTextarea
      {...props}
      placeholder="base64:"
      validate={validate}
      label="Binary value"
    />
  )
}

export default BinaryField
