import { DatePicker } from '@mui/lab'
import { Box, TextField } from '@mui/material'
import moment, { Moment } from 'moment'
import React from 'react'

export interface TimestampRange {
  from: number
  to: number
  enabled: boolean
}

export interface TimestampRangeSelectProps {
  state: TimestampRange
  setState: (state: TimestampRange) => void
}

export const initialTimestampRangeSelectState = (): TimestampRange => ({
  from: 1577836800000, // 	Wed Jan 01 2020 00:00:00 GMT+0000
  to: moment().add(1, 'day').valueOf(),
  enabled: false
})

const TimestampRangeSelect: React.FC<TimestampRangeSelectProps> = (props) => {
  const { state, setState } = props

  const onChange = (date: Moment | null, key: 'from' | 'to') => {
    if (!date) return

    setState({ ...state, [key]: date.valueOf() })
  }

  return (
    <Box>
      <DatePicker
        views={['day']}
        label="From"
        value={moment(state.from)}
        onChange={(date) => onChange(date, 'from')}
        renderInput={(params) => (
          <TextField {...params} size="small" sx={{ marginRight: '10px' }} />
        )}
        maxDate={moment(state.to)}
      />
      <DatePicker
        views={['day']}
        label="To"
        value={moment(state.to)}
        onChange={(date) => onChange(date, 'to')}
        renderInput={(params) => <TextField {...params} size="small" />}
        minDate={moment(state.from)}
      />
    </Box>
  )
}

export default TimestampRangeSelect
