import { Box, Tooltip, TooltipProps } from '@mui/material'
import React from 'react'
import { shortenHash } from '../Common/Utils'
import { useSettings } from '../Providers/SettingsProvider/SettingsProvider'

export interface HashTextProps {
  text: string
  explorerPath?: string
  substringLength?: number
  tooltip?: boolean
  style?: React.CSSProperties
  tooltipPlacement?: TooltipProps['placement']
  copyOnClick?: boolean
  displayValue?: string
  key?: string
}

const HashText: React.FC<HashTextProps> = (props) => {
  const { servicesUrls } = useSettings()
  const url = `${servicesUrls.explorerUrl}${props.explorerPath}/${props.text}`

  const onClick = () => {
    if (props.copyOnClick) {
      window.navigator.clipboard.writeText(props.text)
      return
    }

    if (!props.explorerPath) return

    window.open(url, '_blank')
  }

  const text = (
    <Box
      key={props.key}
      onClick={onClick}
      sx={{
        fontFamily: 'monospace',
        color: '#999',
        cursor: 'pointer',
        '&:hover': {
          filter: 'brightness(1.2)'
        },
        display: 'inline',

        ...(props.style ?? {})
      }}
    >
      {props.displayValue ?? shortenHash(props.text, props.substringLength)}
    </Box>
  )

  if (props.tooltip) {
    return (
      <Tooltip title={props.copyOnClick ? 'Click to copy' : url} placement={props.tooltipPlacement}>
        {text}
      </Tooltip>
    )
  } else {
    return text
  }
}

export default HashText
