import React from 'react'
import { Backdrop, CircularProgress, Link, Typography, useTheme } from '@mui/material'
import Space from './Space'
import { useWvs } from '../Providers/WvsProvider/KeeperProvider'
import { WalletStatus } from '../Blockchain/BCInterfaces'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

export interface LoadingOverlayProps {
  delay?: number
}

const infoText = (status: WalletStatus) => {
  if (status === 'available') return 'Loading ...'
  if (status === 'loading') return 'Loading ...'
  if (status === 'not_available') return 'Keeper unavailable'
  if (status === 'refused') return 'Connection refused by user'
  if (status === 'no_account') return 'No accounts available'
  if (status === 'connected') return 'Connected with errors'

  return ''
}

const renderIcon = (status: WalletStatus) => {
  if (status === 'available' || status === 'loading') {
    return <CircularProgress size={60} />
  }

  return <ErrorOutlineIcon color="error" sx={{ width: '75px', height: '75px' }} />
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = (props) => {
  const [delayReached, setDelayReached] = React.useState(false)
  const { palette } = useTheme()
  const { status, publicState } = useWvs()

  React.useEffect(() => {
    const handle = setTimeout(() => setDelayReached(true), props.delay ?? 500)
    return () => clearTimeout(handle)
  }, [props.delay])

  const open = React.useMemo(() => {
    const connected = status === 'connected' && !!publicState?.account?.address
    return !connected && delayReached
  }, [delayReached, status, publicState])

  return (
    <Backdrop open={open} sx={{ zIndex: 10000, flexDirection: 'column' }}>
      {renderIcon(status)}
      <Space height={30} />
      <Typography sx={{ color: palette.text.secondary }}>{infoText(status)}</Typography>
      <Space />
      {(status === 'refused' || status === 'no_account') && (
        <Link sx={{ cursor: 'pointer' }} onClick={() => window.location.reload()}>
          Reload page
        </Link>
      )}
    </Backdrop>
  )
}

export default LoadingOverlay
