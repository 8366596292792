import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  Box,
  MenuItem,
  SelectChangeEvent,
  ButtonGroup,
  Button
} from '@mui/material'
import React from 'react'
import { randHash } from '../../Common/Utils'
import { transactionsDictionary } from '../../Views/Transactions/TransactionConstants'

export interface TxTypeSelectProps {
  state: number[]
  setState: (state: number[]) => void
}

export const initialTxTypeSelectState = (): number[] =>
  Object.keys(transactionsDictionary).map((key) => Number(key))

const TxTypeSelect: React.FC<TxTypeSelectProps> = ({ state, setState }) => {
  const maxLength = Object.keys(transactionsDictionary).length
  const id = randHash()

  const onChange = (e: SelectChangeEvent<number[]>) => {
    const id = Number(e.target.value[e.target.value.length - 1])

    if (state.includes(id)) {
      setState(state.filter((_id) => _id !== id))
    } else {
      setState([...state, id])
    }
  }

  return (
    <Box>
      <FormControl sx={{ width: '250px', marginRight: '10px' }}>
        <InputLabel id={`${id}-label`}>Transaction types</InputLabel>
        <Select
          labelId={`${id}-label`}
          id={id}
          multiple
          value={state}
          onChange={onChange}
          input={<OutlinedInput label="Transaction types" size="small" />}
          size="small"
        >
          {Object.entries(transactionsDictionary).map(([id, name]) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ButtonGroup size="small">
        <Button
          color="success"
          disabled={state.length === maxLength}
          onClick={() => setState(initialTxTypeSelectState())}
          sx={{ width: '130px !important', height: '43px' }}
        >
          Include all
        </Button>
        <Button
          color="error"
          disabled={state.length === 0}
          onClick={() => setState([])}
          sx={{ width: '130px !important', height: '43px' }}
        >
          Exclude all
        </Button>
      </ButtonGroup>
    </Box>
  )
}

export default TxTypeSelect
