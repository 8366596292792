import React from 'react'
import { DARK_MODE_DEFAULT_VALUE, SKEY_SERVICES_URLS } from '../../Config/config'

export const DARK_MODE_KEY = 'su_dark_mode'
export const SKEY_SERVICES_URLS_KEY = 'su_skey_services_urls'

export interface ServicesUrlsState {
  explorerUrl: string
  skeyBoxAssetServiceUrl: string
  skeyTixAssetServiceUrl: string
  go2nftAssetServiceUrl: string
}

export interface ContextValue {
  network: string // mainnet | testnet
  setNetwork: (network: string) => void
  darkMode: boolean
  setDarkMode: (value: boolean) => void
  servicesUrls: ServicesUrlsState
  setServicesUrls: React.Dispatch<React.SetStateAction<ServicesUrlsState>>
}

export const SettingsContext = React.createContext<ContextValue>(null as any)

const initialServicesUrlsMainnet = (): ServicesUrlsState => ({
  explorerUrl: SKEY_SERVICES_URLS.TESTNET_EXPLORER_URL,
  skeyBoxAssetServiceUrl: SKEY_SERVICES_URLS.MAINNET_SKEY_BOX_ASSET_SERVICE_URL,
  skeyTixAssetServiceUrl: SKEY_SERVICES_URLS.MAINNET_SKEY_TIX_ASSET_SERVICE_URL,
  go2nftAssetServiceUrl: SKEY_SERVICES_URLS.MAINNET_GO2NFT_ASSET_SERVICE_URL
})

const initialServicesUrlsTestnet = (): ServicesUrlsState => ({
  explorerUrl: SKEY_SERVICES_URLS.MAINNET_EXPLORER_URL,
  skeyBoxAssetServiceUrl: SKEY_SERVICES_URLS.MAINNET_SKEY_BOX_ASSET_SERVICE_URL,
  skeyTixAssetServiceUrl: SKEY_SERVICES_URLS.MAINNET_SKEY_TIX_ASSET_SERVICE_URL,
  go2nftAssetServiceUrl: SKEY_SERVICES_URLS.MAINNET_GO2NFT_ASSET_SERVICE_URL
})

export const SettingsProvider: React.FC = (props) => {
  const [network, setNetwork] = React.useState('mainnet')
  const [darkMode, setDarkMode] = React.useState(DARK_MODE_DEFAULT_VALUE)
  const [servicesUrls, setServicesUrls] = React.useState<ServicesUrlsState>(initialServicesUrlsMainnet())

  React.useLayoutEffect(() => {
    const lsDarkMode = window.localStorage.getItem(DARK_MODE_KEY)
    if (lsDarkMode) setDarkMode(lsDarkMode === 'true')

    const lsServicesUrls = window.localStorage.getItem(SKEY_SERVICES_URLS_KEY)
    if (lsServicesUrls) {
      setServicesUrls(JSON.parse(lsServicesUrls))
    } else {
      if (network === 'testnet') setServicesUrls(initialServicesUrlsTestnet())
      else setServicesUrls(initialServicesUrlsMainnet())
    }
  }, [network])

  React.useEffect(() => {
    localStorage.setItem(DARK_MODE_KEY, darkMode ? 'true' : 'false')
    localStorage.setItem(SKEY_SERVICES_URLS_KEY, JSON.stringify(servicesUrls))
  }, [darkMode, servicesUrls])

  const contextValue: ContextValue = {
    network,
    setNetwork,
    darkMode,
    setDarkMode,
    servicesUrls,
    setServicesUrls
  }

  return <SettingsContext.Provider value={contextValue}>{props.children}</SettingsContext.Provider>
}

export const useSettings = () => React.useContext(SettingsContext)
