import React from 'react'
import Space from '../../Components/Space'
import BooleanSelect from './Fields/BooleanSelect'
import { DataType } from './DataInterfaces'
import StringField from './Fields/StringField'
import IntegerField from './Fields/IntegerField'
import BinaryField from './Fields/BinaryField'
import { Box } from '@mui/system'
import { useTransaction } from '../../Providers/WvsProvider/useTransaction'
import {
  initialCustomInputState,
  useCustomInput
} from '../../Components/Inputs/CustomInput'
import CustomTextarea from '../../Components/Inputs/CustomTextarea'
import CustomSelect from '../../Components/Inputs/CustomSelect'
import TxButton from '../../Components/TxButton'
import { useTransactionNotification } from '../../Providers/NotificationProvider/useTransactionNotification'
import { useWvs } from '../../Providers/WvsProvider/KeeperProvider'
import DeleteField from './Fields/DeleteField'
import TxErrorDialog from '../../Components/TxErrorDialog'

export const matchField = (type: DataType) => {
  switch (type) {
    case 'binary':
      return BinaryField
    case 'boolean':
      return BooleanSelect
    case 'integer':
      return IntegerField
    case 'string':
      return StringField
    case 'delete':
      return DeleteField
    default:
      return StringField
  }
}

const DataEntry: React.FC = () => {
  const key = useCustomInput()
  const value = useCustomInput()
  const type = useCustomInput('string')
  const tx = useTransaction()
  useTransactionNotification(tx.state.status)
  const { services } = useWvs()

  const disabled = !!key.state.error || !key.state.touched || !!value.state.error

  React.useEffect(() => {
    value.setState({
      ...initialCustomInputState,
      value: type.state.value === 'boolean' ? 'true' : ''
    })

    // eslint-disable-next-line
  }, [type.state.value])

  const ValueField = React.useMemo(() => matchField(type.state.value as any), [type])

  const send = async () => {
    const params = await services.txCreator.createDataTxData(
      key.state.value,
      type.state.value,
      value.state.value
    )

    if (!params) return

    await tx.send(params)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <CustomSelect
        {...type}
        label="Type"
        options={[
          { value: 'string', label: 'String' },
          { value: 'boolean', label: 'Boolean' },
          { value: 'integer', label: 'Integer' },
          { value: 'binary', label: 'Binary' },
          { value: 'delete', label: 'Delete' }
        ]}
      />
      <Space />
      <CustomTextarea {...key} label="Key" required />
      <Space />
      <ValueField {...value} />
      <Space />
      <TxButton
        tx={tx.state}
        onClick={send}
        text="Send"
        disabled={disabled}
        reset={tx.reset}
        wrapper
      />
      <TxErrorDialog tx={tx.state} reset={tx.reset} />
    </Box>
  )
}

export default DataEntry
