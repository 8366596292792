import React from 'react'
import * as Transactions from '@waves/waves-transactions'
import { useWvs } from './KeeperProvider'
import { inspect } from 'util'

export interface TransactionState {
  status: 'ready' | 'pending' | 'success' | 'failed' | 'rejected' | 'waiting'
  txHash: string | null
  error: string | null
}

export const initialTransactionState = (): TransactionState => ({
  status: 'ready',
  txHash: null,
  error: null
})

export const useTransaction = () => {
  const [state, setState] = React.useState(initialTransactionState())
  const { keeper, publicState, services } = useWvs()

  const send = async (params: WavesKeeper.TSignTransactionData) => {
    if (!keeper) throw new Error('keeper not available')

    setState((state) => ({ ...state, status: 'pending' }))

    try {
      const paramsWithFee = await services.feeCalculator.appendFee(params)

      const res = await keeper.signAndPublishTransaction(paramsWithFee)
      const tx = JSON.parse(res)
      setState((state) => ({ ...state, status: 'waiting', txHash: tx.id }))

      await Transactions.waitForTx(
        tx.id,
        {
          apiBase: publicState?.network?.server!
        },
        { credentials: 'omit' }
      ).catch(() => {})

      setState((state) => ({ ...state, status: 'success' }))
      return tx
    } catch (e: any) {
      if (e?.message === 'User denied message') {
        setState((state) => ({ ...state, status: 'rejected' }))
      } else {
        console.error(e)
        setState((state) => ({ ...state, status: 'failed', error: inspect(e) }))
      }

      throw e
    }
  }

  const reset = () => setState(initialTransactionState())

  return { state, send, reset }
}
